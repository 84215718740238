import React from 'react';
import {Provider} from 'react-redux';
import store from "./store";

import ThemeProvider from "./components/ThemeProvider";
import AppContent from "./components/AppContent";


const App = () => (
    <Provider store={store}>
        <ThemeProvider>
            <AppContent/>
        </ThemeProvider>
    </Provider>
);

export default App;