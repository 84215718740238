// src/store/filterSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeFilters: {},
    defaultFilters: {},
    loading: {},
    metadata: {}, // Stores any additional filter-related metadata by entity type
    error: null
};

export const filterSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        updateFilters: (state, action) => {
            const { entityType, filters } = action.payload;
            state.activeFilters[entityType] = {
                ...state.activeFilters[entityType],
                ...filters
            };
            // Clean up empty values
            Object.keys(state.activeFilters[entityType]).forEach(key => {
                if (!state.activeFilters[entityType][key] &&
                    state.activeFilters[entityType][key] !== 0 &&
                    state.activeFilters[entityType][key] !== false) {
                    delete state.activeFilters[entityType][key];
                }
            });
        },
        setDefaultFilters: (state, action) => {
            const { entityType, defaults } = action.payload;
            state.defaultFilters[entityType] = defaults;
            // Initialize active filters if they don't exist
            if (!state.activeFilters[entityType]) {
                state.activeFilters[entityType] = { ...defaults };
            }
        },
        resetFilters: (state, action) => {
            const { entityType } = action.payload;
            state.activeFilters[entityType] = {
                ...state.defaultFilters[entityType]
            };
        },
        setFilterLoading: (state, action) => {
            const { entityType, isLoading } = action.payload;
            state.loading[entityType] = isLoading;
        },
        setFilterMetadata: (state, action) => {
            const { entityType, metadata } = action.payload;
            state.metadata[entityType] = {
                ...state.metadata[entityType],
                ...metadata
            };
        },
        setFilterError: (state, action) => {
            state.error = action.payload;
        },
        clearFilterError: (state) => {
            state.error = null;
        }
    }
});

export const {
    updateFilters,
    setDefaultFilters,
    resetFilters,
    setFilterLoading,
    setFilterMetadata,
    setFilterError,
    clearFilterError
} = filterSlice.actions;

// Selectors
export const selectFilters = (state, entityType) =>
    state.filters.activeFilters[entityType] || {};

export const selectFilterLoading = (state, entityType) =>
    state.filters.loading[entityType] || false;

export const selectFilterMetadata = (state, entityType) =>
    state.filters.metadata[entityType] || {};

export const selectFilterError = (state) => state.filters.error;

export default filterSlice.reducer;