import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchDeals, resetDeals} from '../../store/dealSlice';
import EntityListView from "../../components/EntityListView";

const DEAL_SCHEMA = {
    header: [
        {key: 'name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        {key: 'completed_date', label: 'Completed On', variant: 'body2', color: 'text.secondary'},
    ],
    chip: {key: 'id', color: 'primary'},
};

const DEAL_FILTER_CONFIG = [
    {field: 'status', label: 'Status', type: 'text'},
    {field: 'type', label: 'Type', type: 'text'}
];

const DealsList = ({paramName, id}) => {
    const dispatch = useDispatch();
    const {items, loading, error, pagination} = useSelector((state) => state.deals);

    return (
        <EntityListView
            title={"Deals"}
            items={items}
            loading={loading}
            error={error}
            pagination={pagination}
            createPath="/deals/create"
            filterConfig={DEAL_FILTER_CONFIG}
            cardSchema={DEAL_SCHEMA}
            navigateBasePath="/deals"
            onFetch={(params) => dispatch(fetchDeals(params))}
            onReset={() => dispatch(resetDeals())}
            entityId={id}
            entityIdParam={paramName}
        />
    );
};

export default DealsList;
