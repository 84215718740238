// src/components/schedule/ScheduleOfWorkForm.js
import React, {useState, useCallback} from 'react';
import {
    Box,
    Button,
    Divider,
    Alert,
    Paper,
    Grid,
    Typography,
    CircularProgress,
    Tooltip,
    Snackbar,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import {useForm, FormProvider, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {format} from 'date-fns';
import {updateField} from '../../store/formSlice';
import FormFieldRenderer from "./FormFieldRenderer";
import {publishingScheduleSchema, recordingsScheduleSchema} from "./schemas/scheduleOfWorkSchema";
import apiService from '../../api/apiService';
import {DownloadSimple} from "@phosphor-icons/react";


const FormField = ({field, control, errors}) => {
    return (
        <Box sx={{mb: 3}}>
            <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                <Typography variant="subtitle2" color="textSecondary">
                    {field.label}
                    {field.required && ' *'}
                </Typography>
                {field.tooltip && (
                    <Tooltip
                        title={field.tooltip}
                        placement="right"
                        arrow
                    >
                        <InfoIcon
                            fontSize="small"
                            sx={{
                                ml: 1,
                                color: 'action.active',
                                cursor: 'help',
                                '&:hover': {
                                    color: 'primary.main'
                                }
                            }}
                        />
                    </Tooltip>
                )}
            </Box>
            <Box>
                {field.type === 'switch' ? (
                    <Controller
                        name={field.name}
                        control={control}
                        defaultValue={field.defaultValue}
                        render={({field: {onChange, value, ...rest}}) => (
                            <FormFieldRenderer
                                field={{...field, ...rest}}
                                onChange={onChange}
                                value={value}
                                error={errors[field.name]}
                            />
                        )}
                    />
                ) : (
                    <FormFieldRenderer
                        field={field}
                        error={errors[field.name]}
                    />
                )}
            </Box>
        </Box>
    );
};

const ScheduleOfWorkForm = ({isPublishing}) => {
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formData = useSelector((state) => state.form.formData.scheduleOfWork || {});

    const defaultValues = {
        ...formData,
        is_controlled: true,
        calculate_mech_share: false
    };

    const methods = useForm({
        defaultValues,
        mode: 'onChange',
        criteriaMode: 'all'
    });

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: {errors}
    } = methods;

    const templateUrl = isPublishing
        ? 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQhzNT9KOJm7oUL3OpdWWKe5GJFe69Ak-81recy8cNO7VJoHoX96x-UeogwbH9Hsajv1WMC17WeW7MA/pub?output=csv'
        : 'https://docs.google.com/spreadsheets/d/e/2PACX-1vTsiIKpfGCulGKxqIg-nWnwrBb2V7RGloYlPoS1NksvXwwQWxTPDyocLPiMvRRVN2dTajqKbRUuX-QA/pub?output=csv'


    const watchIsControlled = watch('is_controlled');
    const watchRightType = watch('right_type');

    const processFormData = useCallback((data) => {
        console.log('Raw form data:', data);
        const formDataObj = new FormData();

        Object.entries(data).forEach(([key, value]) => {
            if (value === undefined || value === null) return;

            if (key === 'file' && value) {
                if (Array.isArray(value) && value.length > 0) {
                    console.log('Processing file:', value[0].name);
                    formDataObj.append(key, value[0]);
                } else if (value instanceof File) {
                    console.log('Processing file:', value.name);
                    formDataObj.append(key, value);
                }
            } else if (key === 'territories' && Array.isArray(value)) {
                console.log('Processing territories:', value);
                value.forEach(territory => {
                    console.log('Adding territory:', territory);
                    formDataObj.append('territories', territory);
                });
            } else if (key === 'is_controlled' || key === 'calculate_mech_share') {
                // Ensure boolean values are sent as strings 'true' or 'false'
                console.log(`Processing boolean field ${key}:`, value);
                formDataObj.append(key, String(value));
            } else if (Array.isArray(value)) {
                value.forEach(item => formDataObj.append(`${key}[]`, item));
            } else if (value instanceof Date) {
                const formattedDate = format(value, 'yyyy-MM-dd');
                console.log(`Processing date field ${key}:`, formattedDate);
                formDataObj.append(key, formattedDate);
            } else if (typeof value === 'object') {
                const processedValue = value.id || JSON.stringify(value);
                console.log(`Processing object field ${key}:`, processedValue);
                formDataObj.append(key, processedValue);
            } else {
                console.log(`Processing field ${key}:`, value);
                formDataObj.append(key, value);
            }
        });

        // Debug log the final FormData
        console.log('Final FormData contents:');
        for (let [key, value] of formDataObj.entries()) {
            console.log(`${key}: ${value}`);
        }

        return formDataObj;
    }, []);

    const onSubmit = async (data) => {
        const confirmMessage =
            "By submitting this form, you are agreeing that these rights are correct and you will either upload multiple schedules or manage more complex rights directly in the system.";

        if (!window.confirm(confirmMessage)) return;

        try {
            setIsSubmitting(true);
            setError(null);

            console.log('Form submission started');
            console.log('Raw form data:', data);

            const formDataObj = processFormData(data);
            const endpoint = `/import/schedule/${isPublishing ? 'publishing' : 'recordings'}`;

            console.log('Submitting to endpoint:', endpoint);

            try {
                const response = await apiService.post(endpoint, formDataObj);
                console.log('API Response:', response);

                dispatch(updateField({formName: 'scheduleOfWork', data: response.data}));
                setSnackbarOpen(true);
                navigate(-1);
            } catch (err) {
                console.error('API Error Details:', {
                    status: err.response?.status,
                    statusText: err.response?.statusText,
                    data: err.response?.data,
                    headers: err.response?.headers
                });

                if (err.response?.data?.detail) {
                    console.error('Validation Errors:', err.response.data.detail);
                    const errorMessage = err.response.data.detail.map(error =>
                        `${error.loc.join('.')}: ${error.msg}`
                    ).join(', ');
                    throw new Error(errorMessage);
                }

                throw err;
            }
        } catch (err) {
            const errorMessage = err.message || 'An error occurred while submitting the form';
            console.error('Error handling:', errorMessage);
            setError(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        reset(defaultValues);
        setError(null);
    };

    const renderFormFields = () => {
        const schema = isPublishing ? publishingScheduleSchema : recordingsScheduleSchema;
        const allFields = schema.reduce((acc, step) => [...acc, ...step.fields], []);

        const rightTypeField = allFields.find(field => field.name === 'right_type');
        const dealField = allFields.find(field => field.name === 'deal_id');
        const territoriesField = allFields.find(field => field.name === 'territories');
        const controlField = allFields.find(field => field.name === 'is_controlled');
        const fileField = allFields.find(field => field.name === 'file');

        return (
            <Grid container spacing={3}>
                {/* Right Type Field */}
                <Grid item xs={12} sm={6}>
                    <FormField
                        field={rightTypeField}
                        control={control}
                        errors={errors}
                    />
                </Grid>

                {/* Deal Field */}
                <Grid item xs={12} sm={6}>
                    <FormField
                        field={dealField}
                        control={control}
                        errors={errors}
                    />
                </Grid>

                {/* Territories Field */}
                <Grid item xs={12} sm={6}>
                    <FormField
                        field={territoriesField}
                        control={control}
                        errors={errors}
                    />
                </Grid>

                {/* Control Field */}
                <Grid item xs={12} sm={6}>
                    <FormField
                        field={controlField}
                        control={control}
                        errors={errors}
                    />
                </Grid>

                {/* Conditional Fields when is_controlled is false */}
                {!watchIsControlled && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                field={{
                                    name: 'third_party_admin',
                                    label: 'Third Party Admin',
                                    type: 'text',
                                    required: true,
                                    tooltip: 'Enter the third party administrator managing these rights'
                                }}
                                control={control}
                                errors={errors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField
                                field={{
                                    name: 'reversion_date',
                                    label: 'Reversion Date',
                                    type: 'date',
                                    required: true,
                                    tooltip: 'Enter the date when rights revert'
                                }}
                                control={control}
                                errors={errors}
                            />
                        </Grid>
                    </>
                )}

                {/* Mechanical Share Calculation for Publishing */}
                {isPublishing && watchRightType === 'publisher_share' && (
                    <Grid item xs={12}>
                        <Controller
                            name="calculate_mech_share"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label="Auto calculate Mechanical Share?"
                                />
                            )}
                        />
                    </Grid>
                )}

                {/* File Upload Field */}
                {fileField && (
                    <Grid item xs={12}>
                        <FormField
                            field={fileField}
                            control={control}
                            errors={errors}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Paper elevation={3} sx={{p: 4, maxWidth: 1000}}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                        <Typography variant="h5">
                            {isPublishing ? 'Publishing' : 'Recording'} Schedule Import
                        </Typography>
                        <Button
                            variant="outlined"
                            startIcon={<DownloadSimple/>}
                            href={templateUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Download Template
                        </Button>
                    </Box>

                    {error && (
                        <Alert severity="error" sx={{mb: 3}} onClose={() => setError(null)}>
                            {error}
                        </Alert>
                    )}

                    {renderFormFields()}

                    <Divider sx={{my: 3}}/>

                    <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button onClick={handleReset} disabled={isSubmitting}>
                            Reset
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSubmitting || Object.keys(errors).length > 0}
                            startIcon={isSubmitting && <CircularProgress size={20}/>}
                        >
                            Submit
                        </Button>
                    </Box>
                </Paper>

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={() => setSnackbarOpen(false)}
                    message="Schedule uploaded successfully!"
                />
            </form>
        </FormProvider>
    );
};

export const ScheduleOfWorkFormPublishing = () => <ScheduleOfWorkForm isPublishing/>;
export const ScheduleOfWorkFormRecordings = () => <ScheduleOfWorkForm isPublishing={false}/>;

export default ScheduleOfWorkForm;