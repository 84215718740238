import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchWorks, resetWorks} from "../../store/workSlice";
import EntityListView from '../../components/EntityListView';
import {fetchTracks, resetTracks} from "../../store/trackSlice";

const WORK_CARD_SCHEMA = {
    header: [
        {key: 'name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        {key: 'artist_name', label: 'Artist', variant: 'body2', color: 'text.secondary'},
    ],
    chip: {key: 'id', color: 'primary'},
};

const WORK_FILTER_CONFIG = [
];

const TracksList = ({artistId= null}) => {
    const dispatch = useDispatch();
    const {items, loading, error, pagination} = useSelector((state) => state.tracks);


    return (
        <EntityListView
            title="Tracks"
            items={items}
            loading={loading}
            error={error}
            pagination={pagination}
            createPath="/works/create"
            filterConfig={WORK_FILTER_CONFIG}
            cardSchema={WORK_CARD_SCHEMA}
            navigateBasePath="/tracks"
            onFetch={(params) => dispatch(fetchTracks(params))}
            onReset={() => dispatch(resetTracks())}
            entityId={artistId}
            entityIdParam="artistId"
        />
    );
};

export default TracksList;