import React, {useMemo, useCallback} from 'react';
import {useParams} from 'react-router-dom';
import GenericEntityView from '../../components/data/GenericEntityView';
import {BFMTemplatePage} from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import DealsList from "../deals/DealsList";
import {Gear, Handshake, Notepad, Paperclip} from "@phosphor-icons/react";
import {useDispatch, useSelector} from 'react-redux';
import {fetchArtistById, updateArtist} from '../../store/artistSlice';
import {useForm} from 'react-hook-form';
import TracksList from "../tracks/TracksList";

const ARTIST_SCHEMA = [
    {dataKey: 'id', label: 'Artist ID'},
    {dataKey: 'name', label: 'Name', editable: true, titleKey: true},
    {dataKey: 'genre', label: 'Genre', editable: true},
    {dataKey: 'location', label: 'Location', editable: true}
];

const ArtistView = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {item: artist, loading, error} = useSelector((state) => state.artists);

    const {control, handleSubmit, reset} = useForm({
        defaultValues: artist || {}
    });

    const fetchData = useCallback(
        async (id) => {
            try {
                const data = await dispatch(fetchArtistById(id)).unwrap();
                reset(data);
                return data;
            } catch (err) {
                console.error("Error fetching artist:", err);
                throw err;
            }
        },
        [dispatch, reset]
    );

    const updateDataHandler = useCallback(
        async (data) => {
            try {
                const updatedData = await dispatch(updateArtist({id, data})).unwrap();
                reset(updatedData);
                return updatedData;
            } catch (err) {
                console.error("Error updating artist:", err);
                throw err;
            }
        },
        [dispatch, id, reset]
    );

    const onSubmit = async (data) => {
        try {
            await updateDataHandler(data);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const tabSchema = useMemo(
        () => [
            {
                label: 'Tracks',
                icon: <Handshake size={23}/>,
                component: () => <TracksList artistId={id}/>,
            },
            {
                label: 'Attachments',
                icon: <Paperclip size={23}/>,
                component: AttachmentsComponent,
            },
            {
                label: 'Notes',
                icon: <Notepad size={23}/>,
                component: NotesComponent,
            },
            {
                label: 'Metadata',
                icon: <Gear size={23}/>,
                component: MetadataComponent,
            },
        ],
        [id]
    );

    return (
        <BFMTemplatePage
            component={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GenericEntityView
                        schema={ARTIST_SCHEMA}
                        tabSchema={tabSchema}
                        entityName="Artist"
                        title="Artist"
                        fetchData={fetchData}
                        loading={loading}
                        error={error}
                        entityData={artist}
                        control={control}
                        reset={reset}
                    />
                </form>
            }
        />
    );
};

export default ArtistView;