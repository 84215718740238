import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import apiService from '../api/apiService';

const initialState = {
    items: [],
    loading: false,
    error: null,
    pagination: null
};

export const fetchTrackRecordings = createAsyncThunk(
    'trackRecordings/fetchTrackRecordings',
    async ({id, params = {}}, {rejectWithValue}) => {
        try {
            return await apiService.get(`/tracks/${id}/recordings`, params);
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const trackRecordingsSlice = createSlice({
    name: 'trackRecordings',
    initialState,
    reducers: {
        resetTrackRecordings: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTrackRecordings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTrackRecordings.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload.recordings|| [];
                state.pagination = action.payload.pagination || null;
            })
            .addCase(fetchTrackRecordings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export const {resetTrackRecordings} = trackRecordingsSlice.actions;

export default trackRecordingsSlice.reducer;