import React, {useState, useEffect, useCallback} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    AppBar,
    Toolbar,
    Typography,
    Avatar,
    Box,
    Container,
    Drawer,
    List,
    ListItemButton,
    ListSubheader,
    InputBase,
    Divider,
    IconButton,
    Tooltip,
    ListItemText,
    Menu,
    MenuItem,
    ListItem,
    Paper,
    Chip,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {
    Menu as MenuIcon,
    HelpOutline as HelpIcon,
    Search as SearchIcon,
    Close as CloseIcon,
} from "@mui/icons-material";
import {
    ArrowCircleLeft,
    Building,
    Handshake,
    Microphone,
    MusicNoteSimple,
    PaintBrush,
    PencilLine, PianoKeys, Wrench
} from "@phosphor-icons/react";
import {useSelector, useDispatch} from "react-redux";
import {signOutUser} from "../../services/authService";
import apiService from "../../api/apiService";

const navData = {
    general: [
        {label: "Business Entities", route: "/business-entities", icon: <Building size={23}/>},
        {label: "Deals", route: "/deals", icon: <Handshake size={23}/>},
    ],
    recordings: [
        {label: "Artists", route: "/artists", icon: <PaintBrush size={23}/>},
        // {label: "Recordings", route: "/recordings", icon: <Microphone size={23}/>},
        {label: "Tracks", route: "/tracks", icon: <MusicNoteSimple size={23}/>},
    ],
    publishing: [
        {label: "Writers", route: "/writers", icon: <PencilLine size={23}/>},
        {label: "Works", route: "/works", icon: <PianoKeys size={23}/>},
    ],
    utilities: [{label: "Utilities", route: "/utilities", icon: <Wrench size={23}/>}],
};

const Navbar = () => {
    const [drawerExpanded, setDrawerExpanded] = useState(
        JSON.parse(localStorage.getItem("drawerExpanded")) ?? true
    );
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem("drawerExpanded", JSON.stringify(drawerExpanded));
    }, [drawerExpanded]);

    const handleSignOut = () => {
        setAnchorEl(null);
        signOutUser(dispatch);
    };

    // Map nodeLabel to routes
    const mapNodeLabelToRoute = (nodeLabel, nodeId) => {
        const routes = {
            Work: `/works/${nodeId}`,
            Writer: `/writers/${nodeId}`,
            Artist: `/artist/${nodeId}`,
            Recording: `/recording/${nodeId}`,
            Track: `/track/${nodeId}`,
            Deal: `/deals/${nodeId}`,
            BusinessEntity: `/business-entity/${nodeId}`,
        };
        return routes[nodeLabel] || null;
    };

    // Fetch search results with debounce
    const fetchSearchResults = useCallback(
        async (term) => {
            if (!term.trim()) {
                setSearchResults([]);
                return;
            }

            setLoading(true);
            try {
                const {items} = await apiService.get("/search", {q: term});
                setSearchResults(items || []);
            } catch (error) {
                console.error("Search error:", error);
                setSearchResults([]);
            } finally {
                setLoading(false);
            }
        },
        [setSearchResults]
    );

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            fetchSearchResults(searchTerm);
        }, 300); // 300ms debounce

        return () => clearTimeout(debounceTimeout);
    }, [searchTerm, fetchSearchResults]);

    const renderSearchDropdown = () => (
        <Paper
            sx={{
                position: "absolute",
                top: 50,
                left: 0,
                right: 0,
                maxHeight: "300px",
                overflowY: "auto",
                zIndex: theme.zIndex.appBar + 1,
            }}
        >
            {loading && (
                <Box p={2}>
                    <Typography variant="body2" color="textSecondary">
                        Searching...
                    </Typography>
                </Box>
            )}
            {!loading && searchResults.length === 0 && (
                <Box p={2}>
                    <Typography variant="body2" color="textSecondary">
                        No results found.
                    </Typography>
                </Box>
            )}
            {!loading &&
                searchResults.map((result) => (
                    <ListItem
                        button
                        key={result.nodeId}
                        onClick={() => {
                            const route = mapNodeLabelToRoute(result.nodeLabel, result.nodeId);
                            if (route) {
                                navigate(route);
                                setSearchTerm(""); // Clear the search term
                                setSearchResults([]); // Clear the search results
                            }
                        }}
                    >
                        <ListItemText
                            primary={
                                <Box sx={{display: "flex", alignItems: "center", gap: 1}}>
                                    <Typography>{result.name}</Typography>
                                    <Chip
                                        label={result.nodeLabel}
                                        size="small"
                                        sx={{fontSize: "0.75rem"}}
                                    />
                                </Box>
                            }
                            secondary={`ID: ${result.nodeId}`}
                        />
                    </ListItem>
                ))}
        </Paper>
    );

    return (
        <>
            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "black",
                    zIndex: theme.zIndex.drawer + 1,
                    transition: "all 0.4s ease-in-out",
                    borderBottom: "1px solid white",
                }}
            >
                {/*<Container maxWidth="xl">*/}
                <Box sx={{marginLeft: '11vw', marginRight: '11vw'}}>

                    <Toolbar sx={{display: "flex", justifyContent: "space-between", padding: 0}}>
                        <Box display="flex" alignItems="center" gap={1}>
                            <img
                                src="/BFM.jpeg"
                                alt="Logo"
                                style={{
                                    width: 40,
                                    height: 40,
                                    transition: "opacity 0.4s ease",
                                }}
                            />
                            <Typography
                                variant="h6"
                                component={Link}
                                to="/"
                                color="inherit"
                                sx={{textDecoration: "none", fontFamily: "var(--sans)"}}
                            >
                                RMS
                            </Typography>
                        </Box>

                        <Box sx={{display: "flex", alignItems: "center", gap: 2, position: "relative"}}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    backgroundColor: "rgba(255, 255, 255, 0.15)",
                                    borderRadius: 1,
                                    px: 2,
                                    flexGrow: 1,
                                }}
                            >
                                <SearchIcon/>
                                <InputBase
                                    placeholder="Search..."
                                    inputProps={{"aria-label": "search"}}
                                    sx={{
                                        ml: 1,
                                        flex: 1,
                                        backgroundColor: "transparent" // Consistent background
                                    }}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                {searchTerm && (
                                    <IconButton
                                        size="small"
                                        onClick={() => setSearchTerm("")}
                                        sx={{color: "white"}}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                )}
                            </Box>
                            {searchTerm && renderSearchDropdown()}

                            {user ? (
                                <>
                                    <Avatar
                                        src={user.photoURL}
                                        alt={user.displayName || "User"}
                                        sx={{width: 32, height: 32, cursor: "pointer"}}
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                    />
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
                                    </Menu>
                                </>
                            ) : (
                                <Typography
                                    variant="body2"
                                    component={Link}
                                    to="/signin"
                                    sx={{textDecoration: "none", color: "white"}}
                                >
                                    Sign In
                                </Typography>
                            )}

                            <Tooltip title="Get help">
                                <IconButton sx={{color:'white'}}>
                                    <Link to={'/help'}>
                                        <HelpIcon/>
                                    </Link>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </Box>
                {/*</Container>*/}
            </AppBar>

            <Drawer
                variant="permanent"
                anchor="left"
                sx={{
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerExpanded ? 220 : 70,
                        boxSizing: "border-box",
                        marginTop: 8,
                        backgroundColor: "black",
                        color: "var(--white)",
                        transition: "width 0.4s ease-in-out",
                    },
                }}
            >
                <Box
                    sx={{
                        width: drawerExpanded ? 220 : 70,
                        height: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "black",
                        color: "var(--white)",
                        transition: "width 0.4s ease-in-out",
                    }}
                >
                    <IconButton
                        sx={{
                            color: "var(--white)",
                            margin: "10px auto",
                            transform: drawerExpanded ? "rotate(0deg)" : "rotate(180deg)",
                            transition: "transform 0.3s ease-in-out",
                        }}
                        onClick={() => setDrawerExpanded((prev) => !prev)}
                    >
                        <ArrowCircleLeft/>
                    </IconButton>
                    <List sx={{flexGrow: 1, pt: 2}}>
                        {Object.entries(navData).map(([parentKey, items]) => (
                            <React.Fragment key={parentKey}>
                                <Divider/>
                                <ListSubheader
                                    sx={{
                                        backgroundColor: "black",
                                        color: "var(--orange)",
                                        fontWeight: "bold",
                                        display: drawerExpanded ? "block" : "none",
                                        transition: "opacity 0.3s ease-in-out",
                                        opacity: drawerExpanded ? 1 : 0,
                                    }}
                                >
                                    {parentKey.charAt(0).toUpperCase() + parentKey.slice(1)}
                                </ListSubheader>
                                {items.map((item) => (
                                    <Tooltip
                                        title={!drawerExpanded ? item.label : ""}
                                        placement="right"
                                        arrow
                                        key={item.route}
                                    >
                                        <ListItemButton
                                            component={Link}
                                            to={item.route}
                                            sx={{
                                                color: "var(--white)",
                                                justifyContent: drawerExpanded ? "initial" : "center",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "row",
                                                transition: "all 0.3s ease-in-out",
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    minWidth: 50,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {item.icon}
                                            </Box>
                                            <ListItemText
                                                primary={item.label}
                                                sx={{
                                                    ml: 0,
                                                    whiteSpace: "nowrap",
                                                    opacity: drawerExpanded ? 1 : 0,
                                                    transition: "opacity 0.3s ease-in-out",
                                                    "& .MuiTypography-root": {
                                                        fontFamily: "var(--mono)",
                                                        fontSize: "0.75rem",
                                                    }
                                                }}
                                            />
                                        </ListItemButton>
                                    </Tooltip>
                                ))}
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            </Drawer>

            <Box
                sx={{
                    marginLeft: drawerExpanded ? 220 : 70,
                    padding: 3,
                    transition: "margin-left 0.4s ease-in-out",
                }}
            />
        </>
    );
};

export default Navbar;