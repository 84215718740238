import React from 'react';
import {Box, Typography, Grid} from '@mui/material';
import {Upload, UserCircle, ArrowsClockwise, FileText} from '@phosphor-icons/react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from "react-redux";

const UTILITIES_OPTIONS = [
    {
        id: 'file-uploader',
        title: 'File Uploader',
        description: 'Upload and manage files efficiently.',
        icon: <Upload size={32}/>,
        link: '/utilities/file-uploader',
    },
    {
        id: 'user-management',
        title: 'User Management',
        description: 'Manage users and their permissions.',
        icon: <UserCircle size={32}/>,
        link: '/utilities/user-management',
    },
    {
        id: 'refresh-weekly-report',
        title: 'Refresh Weekly Stream Report',
        description: 'Update and regenerate the weekly streaming report.',
        icon: <ArrowsClockwise size={32}/>,
        link: '/utilities/refresh-weekly-report',
    },
    {
        id: 'covenant-report',
        title: 'Covenant Report',
        description: 'Download the covenant report in CSV format.',
        icon: <FileText size={32}/>,
        link: '/utilities/covenant-report',
    },
];

const UtilitiesPage = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const filteredOptions = UTILITIES_OPTIONS.filter(option => {
        console.log(user, "I AM USER")
        if (option.id === 'user-management') {
            return user.access_level === 'ADMIN';
        }
        // Add any additional access control for covenant report if needed
        if (option.id === 'covenant-report') {
            return user.access_level === 'ADMIN' || user.access_level === 'MANAGER';
        }
        return true;
    });

    return (
        <Box p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4">Utilities</Typography>
            </Box>

            <Grid container spacing={3}>
                {filteredOptions.map((option) => (
                    <Grid item xs={12} sm={6} md={4} key={option.id}>
                        <Box
                            sx={{
                                border: '1px solid #e0e0e0',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                transition: 'all 0.3s',
                                '&:hover': {
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() => navigate(option.link)}
                        >
                            <Box mb={2} display="flex" justifyContent="center">
                                {option.icon}
                            </Box>
                            <Typography variant="h6" sx={{fontWeight: 'bold'}}>
                                {option.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {option.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default UtilitiesPage;