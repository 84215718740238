import React from 'react';
import {useLocation, Link as RouterLink} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

const BreadcrumbsNav = () => {
    const location = useLocation();

    // Custom mapping for route labels to match your navData structure
    const routeLabels = {
        'business-entities': 'Business Entities',
        'deals': 'Deals',
        'recordings': 'Recordings',
        'artists': 'Artists',
        'tracks': 'Tracks',
        'publishing': 'Publishing',
        'writers': 'Writers',
        'works': 'Works',
        'utilities': 'Utilities'
    };

    const pathSegments = location.pathname
        .split('/')
        .filter(segment => segment !== '');

    const breadcrumbItems = pathSegments.map((segment, index) => {
        const url = `/${pathSegments.slice(0, index + 1).join('/')}`;
        const label = routeLabels[segment] || segment.charAt(0).toUpperCase() + segment.slice(1).replace(/-/g, ' ');

        const isLast = index === pathSegments.length - 1;

        return isLast ? (
            <Typography
                key={url}
                sx={{
                    color: 'var(--orange)',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.875rem',
                    fontWeight: 500
                }}
            >
                {label}
            </Typography>
        ) : (
            <Link
                key={url}
                component={RouterLink}
                to={url}
                sx={{
                    color: 'var(--dark-grey)',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '0.875rem',
                    textDecoration: 'none',
                    '&:hover': {
                        color: 'var(--orange)',
                        textDecoration: 'underline'
                    }
                }}
            >
                {label}
            </Link>
        );
    });

    breadcrumbItems.unshift(
        <Link
            key="home"
            component={RouterLink}
            to="/"
            sx={{
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                textDecoration: 'none',
                '&:hover': {
                    color: 'var(--orange)',
                    textDecoration: 'underline'
                }
            }}
        >
            <HomeIcon sx={{mr: 0.5, fontSize: '1rem'}}/>
            Home
        </Link>
    );

    return breadcrumbItems.length > 1 ? (
        <Box
            sx={{
                // backgroundColor: 'var(--dark-grey)',
                borderBottom: '1px solid black',
                width: '100%',
                position: 'fixed',
                top: '64px',
                zIndex: 1100,
                paddingLeft: '24px'
            }}
        >
            <Box
                sx={{
                    ml: '11vw', // Aligns with the logo
                    py: 1,
                }}
            >
                <Breadcrumbs
                    separator={
                        <NavigateNextIcon
                            fontSize="small"
                            sx={{
                                color: 'white',
                                fontSize: '1rem'
                            }}
                        />
                    }
                    aria-label="breadcrumb"
                    sx={{
                        '& .MuiBreadcrumbs-separator': {
                            mx: 1
                        }
                    }}
                >
                    {breadcrumbItems}
                </Breadcrumbs>
            </Box>
        </Box>
    ) : null;
};

export default BreadcrumbsNav;