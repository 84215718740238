// src/pages/works/WorksList.js
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchWorks, resetWorks} from "../../store/workSlice";
import EntityListView from '../../components/EntityListView';

const WORK_CARD_SCHEMA = {
    header: [
        {key: 'name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        {key: 'iswc', label: 'ISWC', variant: 'body2', color: 'text.secondary'},
    ],
    chip: {key: 'id', color: 'primary'},
};

const WORK_FILTER_CONFIG = [
    {
        field: 'iswc',
        label: 'ISWC',
        type: 'text'
    },
    {
        field: 'writerName',
        label: 'Writer Name',
        type: 'text'
    }
];

const WorksList = ({writerId = null}) => {
    const dispatch = useDispatch();
    const {items, loading, error, pagination} = useSelector((state) => state.works);

    return (
        <EntityListView
            title="Works"
            items={items}
            loading={loading}
            error={error}
            pagination={pagination}
            createPath="/works/create"
            filterConfig={WORK_FILTER_CONFIG}
            cardSchema={WORK_CARD_SCHEMA}
            navigateBasePath="/works"
            onFetch={(params) => dispatch(fetchWorks(params))}
            onReset={() => dispatch(resetWorks())}
            entityId={writerId}
            entityIdParam="writerId"
        />
    );
};

export default WorksList;