import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchWriters, resetWriters} from '../../store/writerSlice';
import EntityListView from "../../components/EntityListView";

const WRITER_SCHEMA = {
    header: [
        {key: 'name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        {key: 'ipi', label: 'IPI', variant: 'body2'},
    ],
    chip: {key: 'id', color: 'primary'},
};

const WRITER_FILTER_CONFIG = [
    {field: 'ipi', label: 'IPI', type: 'text'},
    {field: 'status', label: 'Status', type: 'text'}
];

const WritersList = ({workId= null}) => {
    const dispatch = useDispatch();
    const {items, loading, error, pagination} = useSelector((state) => state.writers);

    return (
        <EntityListView
            title="Writers"
            items={items}
            loading={loading}
            error={error}
            pagination={pagination}
            createPath="/writers/create"
            filterConfig={WRITER_FILTER_CONFIG}
            cardSchema={WRITER_SCHEMA}
            navigateBasePath="/writers"
            onFetch={(params) => dispatch(fetchWriters(params))}
            onReset={() => dispatch(resetWriters())}
            entityId={workId}
            entityIdParam="workId"
        />
    );
};

export default WritersList;