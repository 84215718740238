import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {TextField, Typography} from "@mui/material";
import {Archive, Gear, Notepad, Paperclip} from "@phosphor-icons/react";
import {fetchDealById, updateDeal, deleteDeal, resetDeals} from '../../store/dealSlice';
import GenericEntityView from '../../components/data/GenericEntityView';
import {BFMTemplatePage} from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import GenericAutocomplete from "../../components/lookup/GenericAutocomplete";
import {useForm} from 'react-hook-form';
import {DealAssets} from "../assets/DealAssets";

const DealView = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [businessEntityInput, setBusinessEntityInput] = useState('');
    const {item: deal, loading, error} = useSelector((state) => state.deals);


    const {control, handleSubmit, reset} = useForm({
        defaultValues: deal || {
            id: '',
            name: '',
            type: '',
            completed_date: '',
            business_entity_id: '',
        }
    });

    const DEAL_SCHEMA = useMemo(() => [
        {dataKey: 'id', label: 'Deal ID', rowPos: 0},
        {
            dataKey: 'name',
            label: 'Name',
            editable: true,
            titleKey: true,
            required: true,
            validation: {
                required: 'Name is required'
            }
        },
        {
            dataKey: 'type',
            label: 'Deal Type',
            editable: true,
            required: true,
            validation: {
                required: 'Deal Type is required'
            },
            render: (controllerField, isEditable) => (
                <TextField
                    {...controllerField}
                    select
                    label="Deal Type"
                    variant="standard"
                    fullWidth
                    margin="dense"
                    disabled={!isEditable}
                    error={!!controllerField.error}
                    helperText={controllerField.error?.message}
                    SelectProps={{
                        native: true,
                    }}
                >
                    <option value="">Select Deal Type</option>
                    <option value="Catalogue Acquisition">Catalogue Acquisition</option>
                    <option value="Company Acquisition">Company Acquisition</option>
                    <option value="Administration">Administration</option>
                </TextField>
            )
        },
        {
            dataKey: 'business_entity_id',
            label: 'Business Entity',
            editable: true,
            rowPos: 1,
            gridSize: 3,
            required: true,
            validation: {
                required: 'Business Entity is required'
            },
            relationshipCheck: true,
            render: (controllerField, isEditable, field) => (
                <GenericAutocomplete
                    label="Business Entity"
                    placeholder="Search business entities"
                    endpoint="/business-entities"
                    dataKey="businessEntities"
                    createNewUrl="/business-entities/create"
                    value={controllerField.value}
                    onChange={controllerField.onChange}
                    error={controllerField.error || field.error}
                    inputRef={controllerField.ref}
                    disabled={!isEditable}
                    onInputChange={(_, value) => setBusinessEntityInput(value)}
                    onCreateNew={() => {
                        navigate('/business-entities/create', {
                            state: {
                                formData: {name: businessEntityInput},
                                returnUrl: `/deals/${id}`
                            }
                        });
                    }}
                />
            )
        },
        {
            dataKey: 'completed_date',
            label: 'Completed Date',
            editable: true,
            rowPos: 1,
            gridSize: 3,
            render: (controllerField, isEditable, field) => (
                <TextField
                    {...controllerField}
                    type="date"
                    label={field.label}
                    variant="standard"
                    fullWidth
                    margin="dense"
                    disabled={!isEditable}
                    error={!!controllerField.error}
                    helperText={controllerField.error?.message}
                    InputLabelProps={{shrink: true}}
                />
            ),
        },
    ], [id, navigate, businessEntityInput]);

    const fetchData = useCallback(async (id) => {
        try {
            const data = await dispatch(fetchDealById(id)).unwrap();
            reset(data);
            return data;
        } catch (error) {
            console.error("Error fetching deal:", error);
            throw error;
        }
    }, [dispatch, reset, id]);

    const updateData = useCallback(async (id, data) => {
        try {
            const response = await dispatch(updateDeal({id, data})).unwrap();
            reset(response);
            return response;
        } catch (error) {
            console.error("Error updating deal:", error);
            throw error;
        }
    }, [dispatch, reset]);

    const deleteData = useCallback(async (id) => {
        try {
            await dispatch(deleteDeal(id)).unwrap();
            console.log(`Deal with ID ${id} deleted`);
            navigate('/deals');
        } catch (error) {
            console.error("Error deleting deal:", error);
            throw error;
        }
    }, [dispatch, navigate]);

    const onSubmit = async (data) => {
        try {
            await updateData(id, data);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };


    useEffect(() => {
        return () => {
            dispatch(resetDeals());  // Reset deal state when component unmounts
        };
    }, [dispatch]);

    // Add effect to fetch deal data when id changes
    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id, fetchData]);

    const tabSchema = useMemo(() => [
        {
            label: 'Assets',
            icon: <Archive size={23}/>,
            component: () => <DealAssets dealId={id}/>
        },
        {
            label: 'Attachments',
            icon: <Paperclip size={23}/>,
            component: () => <AttachmentsComponent entityLabel="deal" entityId={id}/>
        },
        {
            label: 'Notes',
            icon: <Notepad size={23}/>,
            component: () => <NotesComponent entityLabel="deal" entityId={id}/>
        },
        {
            label: 'Metadata',
            icon: <Gear size={23}/>,
            component: (props) => <MetadataComponent {...props} entityId={id}/>
        },
    ], [id]);

    return (
        <BFMTemplatePage
            component={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GenericEntityView
                        schema={DEAL_SCHEMA}
                        tabSchema={tabSchema}
                        entityName="Deal"
                        title="Deal"
                        fetchData={fetchData}
                        updateData={updateData}
                        deleteData={deleteData} // Pass deleteData to GenericEntityView
                        loading={loading}
                        error={error}
                        entityData={deal}
                        control={control}
                        reset={reset}
                    />
                </form>
            }
        />
    );
};

export default DealView;