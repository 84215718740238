import React from 'react';
import {useLocation} from 'react-router-dom';
import GenericEntityCreate from "../../components/forms/GenericEntityCreate";

const DealsCreate = () => {
    const location = useLocation();
    const returnUrl = location.state?.returnTo;

    const schema = [
        {
            name: 'business_entity_id',
            type: 'autocomplete',
            label: 'Business Entity',
            placeholder: 'Search or create new Business Entity',
            endpoint: '/business-entities',
            dataKey: 'businessEntities',
            createNewUrl: '/business-entities/create',
        },
        {
            name: 'name',
            type: 'text',
            label: 'Deal Name',
            placeholder: 'Enter deal name',
        },
        {
            name: 'completed_date',
            type: 'date',
            label: 'Completed Date',
        },
        {
            name: 'type',
            type: 'select',
            label: 'Deal Type',
            options: [
                { value: 'Catalogue Acquisition', label: 'Catalogue Acquisition' },
                { value: 'Company Acquisition', label: 'Company Acquisition' },
                { value: 'Administration', label: 'Administration' }
            ]
        }
    ];

    return (
        <GenericEntityCreate
            title="Create Deal"
            endpoint="/deals"
            schema={schema}
            returnUrl={returnUrl}
        />
    );
};

export default DealsCreate;