import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EntityListView from '../../components/EntityListView';
import {fetchArtists, resetArtists} from "../../store/artistSlice";

const ARTIST_CARD_SCHEMA = {
    header: [
        {key: 'name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        // {key: 'iswc', label: 'ISWC', variant: 'body2', color: 'text.secondary'},
    ],
    chip: {key: 'id', color: 'primary'},
};

const ARTIST_FILTER_CONFIG = [
    // {
    //     field: 'artistName',
    //     label: 'Artist Name',
    //     type: 'text'
    // }
];

const ArtistsList = ({writerId = null}) => {
    const dispatch = useDispatch();
    const {items, loading, error, pagination} = useSelector((state) => state.artists);

    return (
        <EntityListView
            title="Artists"
            items={items}
            loading={loading}
            error={error}
            pagination={pagination}
            createPath="/artists/create"
            filterConfig={ARTIST_FILTER_CONFIG}
            cardSchema={ARTIST_CARD_SCHEMA}
            navigateBasePath="/artists"
            onFetch={(params) => dispatch(fetchArtists(params))}
            onReset={() => dispatch(resetArtists())}
            entityId={writerId}
            entityIdParam="writerId"
        />
    );
};

export default ArtistsList;