import createEntitySlice from './base/baseEntitySlice';

const {reducer, actions} = createEntitySlice({
    name: 'publishers',
    endpoint: '/publishers',
});

export const {
    fetchEntities: fetchPublishers,
    fetchEntityById: fetchPublisherById,
    createEntity: createPublisher,
    updateEntity: updatePublisher,
    deleteEntity: deletePublisher,
    resetState: resetPublishers,
} = actions;

export default reducer;