import React, {useEffect, useState} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useNavigate} from 'react-router-dom';
import {setActiveTab} from '../../store/tabSlice';
import {
    Box,
    Typography,
    CircularProgress,
    Alert,
    Paper,
    Tabs,
    Tab,
    Grid,
    TextField,
    IconButton,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from '@mui/material';
import {LockLaminated, LockLaminatedOpen, FloppyDisk as SaveDisk, X, Trash, Warning} from "@phosphor-icons/react";

const GenericEntityView = ({
                               schema,
                               tabSchema,
                               fetchData,
                               updateData,
                               deleteData,
                               loading: parentLoading,
                               error: parentError,
                               entityData,
                               control,
                           }) => {
    const {id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {activeTab} = useSelector((state) => state.tabs);
    const user = useSelector((state) => state.user);

    const [item, setItem] = useState(entityData || null);
    const [loading, setLoading] = useState(parentLoading || true);
    const [error, setError] = useState(parentError || null);
    const [isEditable, setIsEditable] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [relationshipWarnings, setRelationshipWarnings] = useState({});

    // Effect to fetch initial data
    useEffect(() => {
        const fetchEntityData = async () => {
            if (!entityData && fetchData && id) {
                setLoading(true);
                setError(null);
                try {
                    const data = await fetchData(id);
                    setItem(data);
                } catch (err) {
                    setError(err.message || 'Error fetching entity');
                } finally {
                    setLoading(false);
                }
            } else if (entityData) {
                setLoading(false);
                setItem(entityData);
            }
        };

        fetchEntityData();
    }, [fetchData, id, entityData]);

    useEffect(() => {
        const validateRelationships = () => {
            const warnings = {};
            const relationshipFields = schema.filter(field => field.relationshipCheck);

            console.log('Entity Data:', entityData);
            console.log('Relationship Fields:', relationshipFields);

            for (const field of relationshipFields) {
                const fieldValue = entityData?.[field.dataKey];

                console.log(`Checking ${field.dataKey}:`, {
                    hasField: field.dataKey in entityData,
                    fieldValue,
                    isNull: fieldValue === null,
                    isUndefined: fieldValue === undefined,
                    isEmpty: fieldValue === ''
                });

                // Check if the field exists and has a value
                if (fieldValue === null || fieldValue === undefined || fieldValue === '') {
                    warnings[field.dataKey] = {
                        type: 'warning',
                        message: `${field.label} reference is missing`
                    };
                }
            }

            console.log('Generated Warnings:', warnings);
            setRelationshipWarnings(warnings);
        };

        if (entityData) {
            validateRelationships();
        }
    }, [entityData, schema]);

    const handleUnlockToggle = () => {
        if (user.access_level !== 'VIEWER') {
            setIsEditable(prev => !prev);
        }
    };

    const handleCancel = async () => {
        try {
            await fetchData(id);
        } catch (err) {
            setError(err.message || 'Error refreshing data');
        } finally {
            setIsEditable(false);
        }
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const formData = control._formValues;

            // Ensure all required fields are included
            const payload = {
                id: id,
                ...formData,
            };

            console.log('Payload being sent:', payload); // Debugging

            await updateData(id, payload);
            setIsEditable(false);
        } catch (err) {
            console.error('Error during save:', err);
            setError(err.message || 'Error updating record');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await deleteData(id);
            navigate(-1);
        } catch (err) {
            setError(err.message || 'Error deleting record');
        } finally {
            setLoading(false);
            setDeleteDialogOpen(false);
        }
    };

    const renderTopFields = () => {
        const rowGroups = schema.reduce((acc, field) => {
            if (!field.titleKey) {
                const rowPos = field.rowPos || 0;
                if (!acc[rowPos]) {
                    acc[rowPos] = [];
                }
                acc[rowPos].push(field);
            }
            return acc;
        }, {});

        return Object.entries(rowGroups).map(([rowPos, fields]) => (
            <Grid container spacing={4} key={rowPos} sx={{mb: 4}}>
                {fields.map((field) => {
                    const hasWarning = !!relationshipWarnings[field.dataKey];
                    console.log(`Field ${field.dataKey}:`, {hasWarning, warning: relationshipWarnings[field.dataKey]});

                    return (
                        <Grid item xs={12} sm={6} md={field.gridSize || 3} key={field.dataKey}>
                            <Box position="relative">
                                {hasWarning && (
                                    <Tooltip title={relationshipWarnings[field.dataKey].message}>
                                        <IconButton
                                            size="small"
                                            sx={{
                                                position: 'absolute',
                                                top: -12,
                                                right: -12,
                                                zIndex: 1,
                                                color: 'warning.main'
                                            }}
                                        >
                                            <Warning size={20}/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Controller
                                    name={field.dataKey}
                                    control={control}
                                    rules={field.validation}
                                    render={({field: controllerField, fieldState}) => {
                                        const hasError = !!fieldState?.error;

                                        return field.render ? (
                                            field.render(
                                                {
                                                    ...controllerField,
                                                    error: hasError ? fieldState.error : undefined
                                                },
                                                isEditable,
                                                {
                                                    ...field,
                                                    error: hasError || hasWarning,
                                                    helperText: (hasError && fieldState.error?.message) ||
                                                        (hasWarning && relationshipWarnings[field.dataKey].message)
                                                }
                                            )
                                        ) : (
                                            <TextField
                                                {...controllerField}
                                                label={field.label}
                                                variant="standard"
                                                fullWidth
                                                required={field.required}
                                                margin="dense"
                                                disabled={!isEditable || !field.editable}
                                                error={hasError || hasWarning}
                                                helperText={
                                                    (hasError && fieldState.error?.message) ||
                                                    (hasWarning && relationshipWarnings[field.dataKey].message)
                                                }
                                                InputLabelProps={{shrink: true}}
                                            />
                                        );
                                    }}
                                />
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        ));
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    const titleField = schema.find(field => field.titleKey);

    return (
        <Box p={2}>
            <Paper elevation={2} sx={{p: 3, mb: 2}}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Box flexGrow={1} mr={2}>
                        {titleField && (
                            <Controller
                                name={titleField.dataKey}
                                control={control}
                                rules={titleField.validation}
                                render={({field, fieldState}) => (
                                    <TextField
                                        {...field}
                                        variant="standard"
                                        fullWidth
                                        required={titleField.required}
                                        disabled={!isEditable}
                                        error={!!fieldState?.error}
                                        helperText={fieldState?.error?.message}
                                        InputProps={{
                                            style: {fontSize: '1.5rem'},
                                            disableUnderline: true,
                                        }}
                                        sx={{
                                            '& .MuiInputBase-input': {
                                                fontSize: '1.5rem',
                                                height: '2rem',
                                            }
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Box>
                    {user.access_level !== 'VIEWER' && (
                        <Tooltip title={isEditable ? 'Lock Record' : 'Unlock Record'}>
                            <IconButton onClick={handleUnlockToggle} color="primary">
                                {isEditable ? <LockLaminatedOpen size={23}/> : <LockLaminated size={23}/>}
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
                {renderTopFields()}
                {isEditable && (
                    <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
                        <Tooltip title="Cancel">
                            <IconButton color="secondary" onClick={handleCancel} type="button">
                                <X size={24}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Save">
                            <IconButton color="primary" onClick={handleSave} type="button">
                                <SaveDisk size={24}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton
                                color="error"
                                onClick={() => setDeleteDialogOpen(true)}
                                type="button"
                            >
                                <Trash size={24}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
            </Paper>

            <Tabs
                value={activeTab}
                onChange={(event, newTab) => dispatch(setActiveTab(newTab))}
                sx={{mb: 3}}
            >
                {tabSchema.map((tab, index) => (
                    <Tab key={index} label={tab.label} icon={tab.icon} iconPosition="start"/>
                ))}
            </Tabs>

            <Paper elevation={1} sx={{p: 2}}>
                {React.createElement(tabSchema[activeTab]?.component, {item, control})}
            </Paper>

            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Delete Record</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this record? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default GenericEntityView;