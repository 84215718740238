// BusinessEntitiesList.js
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchBusinessEntities,
    resetBusinessEntities
} from '../../store/businessEntitiesSlice';
import EntityListView from "../../components/EntityListView";
import {fetchDeals, resetDeals} from "../../store/dealSlice";

const BUSINESS_ENTITY_SCHEMA = {
    header: [
        {
            key: 'name',
            variant: 'h6',
            color: 'text.primary',
            sx: {fontWeight: 'bold', mb: 1}
        },
    ],
    chip: {key: 'id', color: 'primary'},
};

const BUSINESS_ENTITY_FILTER_CONFIG = [
    // { field: 'type', label: 'Type', type: 'text' },
    // { field: 'status', label: 'Status', type: 'text' }
];

const BusinessEntitiesList = ({embedded = false}) => {
    const dispatch = useDispatch();
    const {items, loading, error, pagination} = useSelector((state) => state.businessEntities);

    useEffect(() => {
        return () => {
            dispatch(resetBusinessEntities());
        };
    }, [dispatch]);

    return (
        <EntityListView
            title="Business Entities"
            buttonTitle="Business Entity"
            items={items}
            loading={loading}
            error={error}
            pagination={pagination}
            createPath={!embedded ? "/business-entities/create" : null}
            filterConfig={BUSINESS_ENTITY_FILTER_CONFIG}
            cardSchema={BUSINESS_ENTITY_SCHEMA}
            navigateBasePath="/business-entities"
            onFetch={(params) => dispatch(fetchBusinessEntities(params))}
            onReset={() => dispatch(resetBusinessEntities())}
            embedded={embedded}
        />
    );
};

export default React.memo(BusinessEntitiesList);