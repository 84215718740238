import React, { useState, useMemo } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControl,
    FormHelperText,
    IconButton,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { Globe, CaretDown, CaretUp, ChevronRight, ChevronDown } from "@phosphor-icons/react";
import { countryOptions } from '../../data/countries';

const CountrySelector = ({ name, control, rules }) => {
    const {
        field: { value = [], onChange },
        fieldState: { error }
    } = useController({
        name,
        control,
        rules,
        defaultValue: []
    });

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [expandedRegions, setExpandedRegions] = useState({});

    // Process countries data
    const { regions, countries } = useMemo(() => {
        const regions = {};
        const countries = countryOptions
            .filter(country => country.region && country.alpha_2) // Filter out entries without region or alpha_2
            .map(country => ({
                value: country.alpha_2,
                label: country.country,
                region: country.region,
                flag: country.flag
            }));

        countries.forEach(country => {
            if (!regions[country.region]) {
                regions[country.region] = {
                    label: country.region,
                    countries: []
                };
            }
            regions[country.region].countries.push(country);
        });

        return { regions, countries };
    }, []);

    // Calculate if worldwide is selected
    const isWorldwideSelected = value.length === countries.length;

    // Calculate region selection states
    const regionStates = useMemo(() => {
        const states = {};
        Object.entries(regions).forEach(([region, data]) => {
            const regionCountries = data.countries.map(c => c.value);
            const selectedCount = regionCountries.filter(c => value.includes(c)).length;
            states[region] = {
                selected: selectedCount === regionCountries.length,
                indeterminate: selectedCount > 0 && selectedCount < regionCountries.length
            };
        });
        return states;
    }, [value, regions]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleWorldwideToggle = () => {
        if (isWorldwideSelected) {
            onChange([]);
        } else {
            onChange(countries.map(c => c.value));
        }
    };

    const handleRegionToggle = (region) => {
        const regionCountries = regions[region].countries.map(c => c.value);
        if (regionStates[region].selected) {
            onChange(value.filter(v => !regionCountries.includes(v)));
        } else {
            const newValue = [...value, ...regionCountries.filter(c => !value.includes(c))];
            onChange(newValue);
        }
    };

    const handleCountryToggle = (countryValue) => {
        if (value.includes(countryValue)) {
            onChange(value.filter(v => v !== countryValue));
        } else {
            onChange([...value, countryValue]);
        }
    };

    const toggleRegionExpand = (region) => {
        setExpandedRegions((prevState) => ({
            ...prevState,
            [region]: !prevState[region]
        }));
    };

    return (
        <FormControl error={!!error} fullWidth>
            <Button
                variant="outlined"
                onClick={handleClick}
                endIcon={open ? <CaretUp size={20} /> : <CaretDown size={20} />}
                startIcon={<Globe size={20} />}
            >
                {isWorldwideSelected ? "Worldwide" : `${value.length} countries selected`}
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 500,
                        width: 550
                    }
                }}
            >
                {/* Worldwide Option */}
                <MenuItem onClick={handleWorldwideToggle}>
                    <ListItemIcon>
                        <Checkbox
                            checked={isWorldwideSelected}
                            indeterminate={value.length > 0 && !isWorldwideSelected}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Worldwide" />
                </MenuItem>

                <Divider />

                {/* Regions and Countries */}
                {Object.entries(regions).map(([region, data]) => (
                    <Box key={region}>
                        <MenuItem
                            onClick={() => handleRegionToggle(region)}
                            sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={regionStates[region].selected}
                                    indeterminate={regionStates[region].indeterminate}
                                />
                            </ListItemIcon>
                            <ListItemText primary={region} />
                            <IconButton onClick={() => toggleRegionExpand(region)}>
                                {expandedRegions[region] ? <CaretUp size={20} /> : <CaretDown size={20} />}
                            </IconButton>
                        </MenuItem>

                        {/* Countries within region */}
                        <Collapse in={expandedRegions[region] || false} timeout="auto" unmountOnExit>
                            <Box sx={{ pl: 4 }}>
                                {data.countries.map((country) => (
                                    <MenuItem
                                        key={country.value}
                                        onClick={() => handleCountryToggle(country.value)}
                                        sx={{ py: 0.5 }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox checked={value.includes(country.value)} />
                                        </ListItemIcon>
                                        <span style={{ marginRight: 8 }}>{country.flag}</span>
                                        <ListItemText primary={country.label} />
                                    </MenuItem>
                                ))}
                            </Box>
                        </Collapse>
                    </Box>
                ))}
            </Menu>

            {error && (
                <FormHelperText>{error.message}</FormHelperText>
            )}
        </FormControl>
    );
};

export default CountrySelector;