import React, {useState, useEffect} from 'react';
import {
    Box,
    Typography,
    Button,
    CircularProgress,
    Alert,
    Grid,
    Pagination,
    Paper,
    Skeleton,
    Stack,
    Menu,
    MenuItem
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSearchParams} from 'react-router-dom';
import {DownloadSimple, CaretDown} from '@phosphor-icons/react';
import DataCard from "./data/DataCard";
import ItemsPerPageSelector from "./ItemsPerPageSelector";
import EntityFilters from "./EntityFilters";
import apiService from "../api/apiService";

const LoadingSkeleton = () => (
    <Grid container spacing={3}>
        {[1, 2, 3].map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item}>
                <Paper sx={{p: 2}}>
                    <Skeleton variant="text" height={40}/>
                    <Skeleton variant="text"/>
                    <Skeleton variant="text" width="60%"/>
                </Paper>
            </Grid>
        ))}
    </Grid>
);

const escapeCsvValue = (value) => {
    if (value === null || value === undefined) return '';
    const stringValue = String(value);
    if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
        return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
};

const EntityListView = ({
                            title,
                            items,
                            loading,
                            error,
                            pagination,
                            createPath,
                            filterConfig,
                            cardSchema,
                            navigateBasePath,
                            onFetch,
                            onReset,
                            entityId = null,
                            entityIdParam = null
                        }) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
    const [itemsPerPage, setItemsPerPage] = useState(parseInt(searchParams.get('limit')) || 9);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [exportAnchorEl, setExportAnchorEl] = useState(null);

    const handleExportClick = (event) => {
        setExportAnchorEl(event.currentTarget);
    };

    const handleExportClose = () => {
        setExportAnchorEl(null);
    };

    const handleExport = async (type) => {
        try {
            setIsExporting(true);
            const currentFilters = Object.fromEntries(searchParams.entries());

            if (type === 'page') {
                currentFilters.limit = itemsPerPage;
                currentFilters.offset = (page - 1) * itemsPerPage;
            } else {
                currentFilters.limit = 99999999999999999;
                delete currentFilters.offset;
            }

            if (entityId) {
                currentFilters[entityIdParam] = entityId;
            }

            const exportResponse = await onFetch({
                params: currentFilters,
                skipStateUpdate: true
            });

            const exportItems = exportResponse.payload?.items || [];
            if (exportItems.length === 0) return;

            const headers = Object.keys(exportItems[0]);
            const csvHeader = headers.map(escapeCsvValue).join(',');
            const csvRows = exportItems.map(item =>
                headers.map(header => escapeCsvValue(item[header])).join(',')
            );
            const csv = [csvHeader, ...csvRows].join('\n');

            const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${title.toLowerCase()}_${new Date().toISOString().split('T')[0]}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            handleExportClose();
        } catch (error) {
            console.error('Export failed:', error);
        } finally {
            setIsExporting(false);
        }
    };

    const updateUrlParams = (params) => {
        const newParams = new URLSearchParams(searchParams);
        Object.entries(params).forEach(([key, value]) => {
            if (value) {
                newParams.set(key, value.toString());
            } else {
                newParams.delete(key);
            }
        });
        setSearchParams(newParams);
    };

    const fetchData = async (params) => {
        setIsFilterLoading(true);
        try {
            await onFetch({
                params: {
                    ...params,
                    ...(entityId && {[entityIdParam]: entityId})
                }
            });
        } finally {
            setIsFilterLoading(false);
        }
    };

    useEffect(() => {
        const params = {
            offset: (page - 1) * itemsPerPage,
            limit: itemsPerPage,
        };

        if (entityId) {
            params[entityIdParam] = entityId;
        }

        for (const [key, value] of searchParams.entries()) {
            if (!['page', 'limit'].includes(key)) {
                params[key] = value;
            }
        }

        fetchData(params);

        return () => {
            if (!entityId) {
                onReset();
            }
        };
    }, [page, itemsPerPage, entityId, searchParams]);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        updateUrlParams({
            limit: itemsPerPage,
            offset: (newPage - 1) * itemsPerPage
        });
        const currentFilters = Object.fromEntries(searchParams.entries());
        fetchData({
            ...currentFilters,
            limit: itemsPerPage,
            offset: (newPage - 1) * itemsPerPage
        });
    };

    const handleItemsPerPageChange = (newItemsPerPage) => {
        setItemsPerPage(newItemsPerPage);
        setPage(1);

        updateUrlParams({
            page: 1,
            limit: newItemsPerPage,
            offset: 0
        });

        const currentFilters = Object.fromEntries(searchParams.entries());
        fetchData({
            ...currentFilters,
            page: 1,
            limit: newItemsPerPage,
            offset: 0
        });
    };

    const handleFilter = async (filters) => {
        const params = {
            offset: 0,
            limit: itemsPerPage,
            ...(entityId && {[entityIdParam]: entityId}),
            ...filters
        };

        setPage(1);
        updateUrlParams({
            ...filters,
            page: 1,
            limit: itemsPerPage,
            ...(entityId && {[entityIdParam]: entityId})
        });

        await fetchData(params);
    };

    const filterParams = Object.fromEntries(
        Array.from(searchParams.entries()).filter(([key]) => !['page', 'limit'].includes(key))
    );

    if (error) {
        return (
            <Box p={2}>
                <Alert
                    severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={() => fetchData({})}>
                            Retry
                        </Button>
                    }
                >
                    {error}
                </Alert>
            </Box>
        );
    }

    return (
        <Box m={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
               <span>
                   <Typography variant="h4">{title}</Typography>
                   <Typography variant="p">{pagination.rowCount} results</Typography>
               </span>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="outlined"
                        startIcon={<DownloadSimple/>}
                        endIcon={<CaretDown/>}
                        onClick={handleExportClick}
                        disabled={isExporting || items.length === 0}
                    >
                        {isExporting ? 'Exporting...' : `Export ${title}`}
                    </Button>
                    <Menu
                        anchorEl={exportAnchorEl}
                        open={Boolean(exportAnchorEl)}
                        onClose={handleExportClose}
                    >
                        <MenuItem onClick={() => handleExport('page')}>
                            Export Current Page
                        </MenuItem>
                        <MenuItem onClick={() => handleExport('all')}>
                            Export All
                        </MenuItem>
                    </Menu>
                    {createPath && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(createPath)}
                        >
                            Create New {title.slice(0, -1)}
                        </Button>
                    )}
                </Stack>
            </Box>

            <Box mb={1.5} display="flex" gap={2} alignItems="flex-start">
                <ItemsPerPageSelector
                    itemsPerPage={itemsPerPage}
                    onItemsPerPageChange={handleItemsPerPageChange}
                    disabled={isFilterLoading}
                />
            </Box>

            <Box>
                <EntityFilters
                    onFilter={handleFilter}
                    filterConfig={filterConfig}
                    entityType={title.toLowerCase()}
                    searchParamName="name"
                    isLoading={isFilterLoading}
                    initialFilters={filterParams}
                />
            </Box>

            {(loading || isFilterLoading) ? (
                <LoadingSkeleton/>
            ) : items.length > 0 ? (
                <>
                    <Grid container spacing={1.5} mb={4}>
                        {items.map((item) => (
                            <Grid item xs={12} sm={6} md={4} key={item.id}>
                                <DataCard
                                    data={item}
                                    schema={cardSchema}
                                    navigateTo={`${navigateBasePath}/${item.id}`}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Box display="flex" justifyContent="center">
                        <Pagination
                            count={Math.ceil(pagination.rowCount / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                            size="small"
                            disabled={isFilterLoading}
                            siblingCount={1}
                        />
                    </Box>
                </>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="200px"
                    component={Paper}
                    elevation={1}
                >
                    <Typography color="text.secondary">
                        No {title.toLowerCase()} available
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default EntityListView;