import React, {useMemo, useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import GenericEntityView from '../../components/data/GenericEntityView';
import {BFMTemplatePage} from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import DealsList from "../deals/DealsList";
import {Gear, Handshake, Notepad, Paperclip} from "@phosphor-icons/react";
import {useDispatch, useSelector} from 'react-redux';
import {fetchBusinessEntityById, updateBusinessEntity, deleteBusinessEntity} from '../../store/businessEntitiesSlice';
import {useForm} from 'react-hook-form';

const BUSINESS_ENTITY_SCHEMA = [
    {dataKey: 'id', label: 'Business Group ID'},
    {dataKey: 'name', label: 'Name', editable: true, titleKey: true},
];

const BusinessEntityView = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {item: businessEntity, loading, error} = useSelector((state) => state.businessEntities);

    const {control, handleSubmit, reset, formState: {isDirty}} = useForm({
        defaultValues: businessEntity || {}
    });

    useEffect(() => {
        if (businessEntity) {
            reset(businessEntity);
        }
    }, [businessEntity, reset]);

    const fetchData = useCallback(async (id) => {
        try {
            const response = await dispatch(fetchBusinessEntityById(id)).unwrap();
            if (response) {
                reset(response);
                return response;
            }
        } catch (err) {
            console.error("Error fetching business entity:", err);
            throw err;
        }
    }, [dispatch, reset]);

    const updateDataHandler = useCallback(async (formData) => {
        try {
            console.log('Update data:', formData);
            return await dispatch(updateBusinessEntity({
                id,
                data: formData
            })).unwrap();
        } catch (err) {
            console.error("Error updating business entity:", err);
            throw err;
        }
    }, [dispatch, id]);

    const deleteDataHandler = useCallback(async (id) => {
        try {
            await dispatch(deleteBusinessEntity(id)).unwrap();
            navigate(-1);
        } catch (err) {
            console.error("Error deleting business entity:", err);
            throw err;
        }
    }, [dispatch, navigate]);

    const onSubmit = async (data) => {
        console.log(data, 'I AM DATA')
        if (!isDirty) return;
        try {
            await updateDataHandler(data);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    const tabSchema = useMemo(() => [
        {
            label: 'Deals',
            icon: <Handshake size={23}/>,
            component: () => <DealsList paramName={'businessEntityId'} id={id} embedded/>,
        },
        {
            label: 'Attachments',
            icon: <Paperclip size={23}/>,
            component: AttachmentsComponent,
        },
        {
            label: 'Notes',
            icon: <Notepad size={23}/>,
            component: NotesComponent,
        },
        {
            label: 'Metadata',
            icon: <Gear size={23}/>,
            component: MetadataComponent,
        },
    ], [id]);

    return (
        <BFMTemplatePage
            component={
                <form onSubmit={handleSubmit(onSubmit)}>
                    <GenericEntityView
                        schema={BUSINESS_ENTITY_SCHEMA}
                        tabSchema={tabSchema}
                        entityName="Business Entity"
                        title="Business Entity"
                        fetchData={fetchData}
                        updateData={updateDataHandler}
                        deleteData={deleteDataHandler}
                        loading={loading}
                        error={error}
                        entityData={businessEntity}
                        control={control}
                        reset={reset}
                    />
                </form>
            }
        />
    );
};

export default BusinessEntityView;