import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Typography,
    Alert,
    Paper
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateField } from '../../store/formSlice';
import FormFieldRenderer from "./FormFieldRenderer";
import apiService from '../../api/apiService';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

const GenericEntityCreate = ({ title, endpoint, schema, returnUrl }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);

    const formData = useSelector((state) => state.form.formData.genericEntity || {});

    const methods = useForm({
        defaultValues: formData,
        mode: 'onChange',
        criteriaMode: 'all'
    });

    const { handleSubmit, reset, watch } = methods;

    const watchedFields = watch();
    const hasFiles = Object.values(watchedFields).some(
        value => value instanceof File || (Array.isArray(value) && value.some(v => v instanceof File))
    );

    const processFormData = (data) => {
        if (hasFiles) {
            const formDataObj = new FormData();
            Object.entries(data).forEach(([key, value]) => {
                if (value === undefined || value === null) return;
                if (value instanceof File) {
                    formDataObj.append(key, value);
                } else if (Array.isArray(value)) {
                    value.forEach((item) => {
                        formDataObj.append(`${key}[]`, item);
                    });
                } else if (typeof value === 'object') {
                    formDataObj.append(key, value.id || JSON.stringify(value));
                } else {
                    formDataObj.append(key, value);
                }
            });
            return { data: formDataObj, headers: { 'Content-Type': 'multipart/form-data' } };
        } else {
            const jsonData = {};
            Object.entries(data).forEach(([key, value]) => {
                if (value === undefined || value === null) return;
                if (Array.isArray(value)) {
                    jsonData[key] = value;
                } else if (typeof value === 'object') {
                    jsonData[key] = value.id || value;
                } else {
                    jsonData[key] = value;
                }
            });
            return { data: jsonData, headers: { 'Content-Type': 'application/json' } };
        }
    };

    const onSubmit = async (data) => {
        setError(null);
        setIsSubmitting(true);

        try {
            if (data.completed_date instanceof Date && !isNaN(data.completed_date)) {
                data.completed_date = format(data.completed_date, 'yyyy-MM-dd');
            } else if (typeof data.completed_date === 'string') {
                const date = new Date(data.completed_date);
                if (!isNaN(date)) {
                    data.completed_date = format(date, 'yyyy-MM-dd');
                }
            }

            const { data: payload, headers } = processFormData(data);

            const response = await apiService.post(endpoint, payload, { headers });

            dispatch(updateField({
                formType: 'genericEntity',
                data: { ...data },
            }));

            navigate(returnUrl || -1, { replace: true });
        } catch (err) {
            let errorMessage = 'An unexpected error occurred.';
            if (err.response && err.response.data) {
                if (err.response.data.detail && Array.isArray(err.response.data.detail)) {
                    errorMessage = err.response.data.detail.map(e => e.msg).join(' ');
                } else if (typeof err.response.data.message === 'string') {
                    errorMessage = err.response.data.message;
                } else if (typeof err.response.data.message === 'object') {
                    errorMessage = Object.values(err.response.data.message).map(item => item.msg).join(' ');
                } else if (typeof err.response.data.detail === 'string') {
                    errorMessage = err.response.data.detail;
                } else {
                    errorMessage = JSON.stringify(err.response.data.message);
                }
            } else if (err.message) {
                errorMessage = err.message;
            }
            setError(errorMessage);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        methods.reset(formData);
        setError(null);
    };

    return (
        <FormProvider {...methods}>
            <Paper elevation={2} sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
                <Typography variant="h5" gutterBottom>{title}</Typography>

                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}

                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Box display="flex" flexDirection="column" gap={3}>
                        {schema.map((field) => (
                            <Box key={field.name} sx={{ mb: 3 }}>
                                <FormFieldRenderer field={field} />
                            </Box>
                        ))}

                        <Box display="flex" justifyContent="space-between" mt={2}>
                            <Button
                                variant="outlined"
                                onClick={() => navigate(returnUrl || -1, { replace: true })}
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            {/* Submit Button */}
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                                startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
                            >
                                {isSubmitting ? 'Saving...' : 'Submit'}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Paper>
        </FormProvider>
    );
};

export default GenericEntityCreate;