import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiService from "../api/apiService";

// Custom fetch action for deal assets
export const fetchDealAssets = createAsyncThunk(
    'dealAssets/fetch',
    async ({ id, params }, thunkAPI) => {
        try {
            const response = await apiService.get(`/deals/${id}/assets`, params);
            return {
                items: response.assets || [],
                pagination: {
                    rowCount: response.rowCount|| 0,
                    offset: response.offset || 0,
                    limit: response.limit || params.limit || 9,
                }
            };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message || 'Error fetching deal assets');
        }
    }
);

const dealAssetSlice = createSlice({
    name: 'dealAssets',
    initialState: {
        items: [],
        pagination: { rowCount: 0, offset: 0, limit: 9 },
        loading: false,
        error: null,
    },
    reducers: {
        resetDealAssets: (state) => {
            state.items = [];
            state.pagination = { rowCount: 0, offset: 0, limit: 9 };
            state.error = null;
            state.loading = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDealAssets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDealAssets.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.items = payload.items;
                state.pagination = payload.pagination;
            })
            .addCase(fetchDealAssets.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = payload;
            });
    }
});

export const { resetDealAssets } = dealAssetSlice.actions;
export default dealAssetSlice.reducer;