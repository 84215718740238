import {Route, Routes} from "react-router-dom";
import BusinessEntityList from "../pages/businessEntities/BusinessEntityList";
import BusinessEntityCreate from "../pages/businessEntities/BusinessEntityCreate";
import BusinessEntityView from "../pages/businessEntities/BusinessEntityView";
import NoteCreate from "./NoteCreate";
import {BFMTemplatePage} from "../pages/BFMTemplatePage";
import AttachmentCreate from "./attachments/AttachmentManager";
import React from "react";
import UtilitiesPage from "../pages/utilities";
import FileUploader from "./forms/FileUploader";
import DealsList from "../pages/deals/DealsList";
import DealView from "../pages/deals/DealView";
import DealsCreate from "../pages/deals/DealsCreate";
import WritersList from "../pages/writers/WritersList";
import WorkView from "../pages/works/WorkView";
import WriterView from "../pages/writers/WriterView";
import UserManagement from "./UserManagement";
import WorksListContainer from "../pages/works/WorksListContainer";
import ArtistsList from "../pages/artists/ArtistsList";
import RecordingsList from "../pages/recordings/RecordingsList";
import RecordingsView from "../pages/recordings/RecordingsView";
import ArtistView from "../pages/artists/ArtistView";
import TracksList from "../pages/tracks/TracksList";
import {TracksView} from "../pages/tracks/TracksView";
import RefreshWeeklyReport from "../pages/utilities/RefreshWeeklyReport";
import {Help} from "@mui/icons-material";
import HelpPage from "../pages/HelpPage";
import {Home} from "../pages/Home";
import CovenantReportDownload from "../pages/utilities/CovenantReportDownload";

const resources = [
    {
        path: "/business-entities",
        list: BusinessEntityList,
        create: BusinessEntityCreate,
        view: BusinessEntityView,
        nodeLabel: "businessEntity",
    },
    {
        path: "/deals",
        list: DealsList,
        create: DealsCreate,
        view: DealView,
        nodeLabel: "deal",
    },
    {
        path: "/writers",
        list: WritersList,
        create: WriterView,
        view: WriterView,
        nodeLabel: "writer",
    },
    {
        path: "/works",
        list: WorksListContainer,
        view: WorkView,
        nodeLabel: "work",
    },
    {
        path: "/artists",
        list: ArtistsList,
        view: ArtistView,
        nodeLabel: "artist",
    },
    {
        path: "/recordings",
        list: RecordingsList,
        view: RecordingsView,
        nodeLabel: "recording",
    },
    {
        path: "/tracks",
        list: TracksList,
        view: TracksView,
        nodeLabel: "track",
    },
];

export const ApiRouter = () => {
    return (
        <div>
            <Routes>
                <Route path='/' element={<Home/>}/>
                <Route path="/utilities/file-uploader" element={<FileUploader/>}/>
                <Route path="/utilities/user-management" element={<UserManagement/>}/>
                <Route path="/utilities/refresh-weekly-report" element={<RefreshWeeklyReport/>}/>
                <Route path='/help' element={<HelpPage/>}/>
                <Route path="/utilities/covenant-report" element={<CovenantReportDownload/>}/> {/* Fixed path */}

                <Route path="/utilities" element={<UtilitiesPage/>}/>

                {resources.map((resource) => (
                    <React.Fragment key={resource.path}>
                        <Route path={resource.path} element={<resource.list/>}/>
                        {resource.create && (
                            <Route path={`${resource.path}/create`} element={<resource.create/>}/>
                        )}
                        <Route path={`${resource.path}/:id`} element={<resource.view/>}/>
                        <Route
                            path={`${resource.path}/:id/notes/create`}
                            element={<NoteCreate nodeLabel={resource.nodeLabel}/>}
                        />
                        <Route
                            path={`${resource.path}/:id/attachments/manage`}
                            element={
                                <BFMTemplatePage
                                    component={<AttachmentCreate nodeLabel={resource.nodeLabel}/>}
                                />
                            }
                        />
                    </React.Fragment>
                ))}
            </Routes>
        </div>
    );
};