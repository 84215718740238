import createEntitySlice from "./base/baseEntitySlice";


const {reducer, actions} = createEntitySlice({
    name: 'tracks',
    endpoint: '/tracks',
});

export const {
    fetchEntities: fetchTracks,
    fetchEntityById: fetchTrackById,
    createEntity: createTrack,
    updateEntity: updateTrack,
    deleteEntity: deleteTrack,
    resetState: resetTracks,
} = actions;

export default reducer;