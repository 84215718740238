import React, {useEffect, useState} from 'react';
import {Autocomplete, TextField, CircularProgress} from '@mui/material';
import {debounce} from 'lodash';
import apiService from '../../api/apiService';

const GenericAutocomplete = ({
                                 endpoint,
                                 label,
                                 placeholder,
                                 dataKey,
                                 value,
                                 onChange,
                                 disabled,
                                 error,
                                 createNewCallback,
                                 onInputChange
                             }) => {
    // Track the text input value separately from the selected value
    const [inputValue, setInputValue] = useState('');

    // Store the fetched options for the dropdown
    const [options, setOptions] = useState([]);

    // Loading state for API calls
    const [loading, setLoading] = useState(false);

    // Effect to handle searching when user types
    useEffect(() => {
        // Create a debounced search function
        const searchItems = debounce(async (searchText) => {
            // Don't search if the input is empty
            if (!searchText) {
                setOptions([]);
                return;
            }

            setLoading(true);
            try {
                // Make the API call to search for options
                const response = await apiService.get(endpoint, {name: searchText});
                setOptions(response[dataKey] || []);
            } catch (error) {
                console.error('Search failed:', error);
                setOptions([]);
            } finally {
                setLoading(false);
            }
        }, 300); // Wait 300ms after typing before searching

        // Trigger the search
        searchItems(inputValue);

        // Cleanup the debounced function when the component unmounts
        return () => searchItems.cancel();
    }, [inputValue, endpoint, dataKey]);

    // Effect to load details of a selected value
    useEffect(() => {
        const loadInitialValue = async () => {
            // Only load if we have a value and it's not already in our options
            if (value && !options.find(opt => opt.id === value)) {
                try {
                    // Fetch the details of the selected item
                    const response = await apiService.get(`${endpoint}/${value}`);
                    if (response) {
                        // Add the fetched item to our options
                        setOptions(prev => [response, ...prev]);
                    }
                } catch (error) {
                    console.error('Failed to load initial value:', error);
                }
            }
        };

        loadInitialValue();
    }, [value, endpoint, options]);

    return (
        <Autocomplete
            // Core props
            value={options.find(opt => opt.id === value) || null}
            onChange={(_, newValue) => {
                // Pass just the ID to the onChange handler
                onChange(newValue?.id || null);
            }}

            // Input handling
            inputValue={inputValue}
            onInputChange={(_, newValue) => {
                setInputValue(newValue);
                if (onInputChange) onInputChange(newValue);
            }}

            // Options configuration
            options={options}
            getOptionLabel={(option) => option?.name || ''}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}

            // State flags
            loading={loading}
            disabled={disabled}

            // The text input element
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    error={!!error}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading && <CircularProgress size={20}/>}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}

            // Optional create new functionality
            {...(createNewCallback && {
                options: [
                    ...options,
                    ...(inputValue ? [{id: 'create-new', name: `Create "${inputValue}"`}] : [])
                ],
                onChange: (_, newValue) => {
                    if (newValue?.id === 'create-new') {
                        createNewCallback(inputValue);
                    } else {
                        onChange(newValue?.id || null);
                    }
                }
            })}
        />
    );
};

export default GenericAutocomplete;