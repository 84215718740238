import React from 'react';
import {useSelector} from 'react-redux';
import WorksList from './WorksList';

const WorksListContainer = ({writerId = null}) => {
    const {items, error, pagination} = useSelector((state) => state.works);

    return (
        <div>
            <WorksList
                items={items}
                error={error}
                pagination={pagination}
                writerId={writerId}
            />
        </div>
    );
};

export default WorksListContainer;