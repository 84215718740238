import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Typography,
    Paper,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    CircularProgress,
    Alert,
    Chip,
    Grid,
    FormHelperText,
    IconButton,
    Tooltip,
} from '@mui/material';
import {ExpandMore, Add, Delete} from '@mui/icons-material';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm, useFieldArray, Controller} from 'react-hook-form';
import FileDropzone from '../FileDropzone';
import {getCategoryFromFileName, getSubTypesForCategory} from '../../utils/categoryMap';
import apiService from '../../api/apiService';
import {BFMTemplatePage} from "../../pages/BFMTemplatePage";

const AttachmentManager = ({nodeLabel}) => {
    const {id: entityId} = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [initialAttachmentCount, setInitialAttachmentCount] = useState(0);

    const {control, handleSubmit, setValue, watch, reset} = useForm({
        defaultValues: {
            attachments: [],
        },
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'attachments',
        keyName: '_id',
    });

    const currentAttachments = watch('attachments');

    const hasChanges = () => {
        if (initialAttachmentCount > 0 && fields.length === 0) {
            return true;
        }
        const hasNewFiles = currentAttachments.some(att => att.file);
        return hasNewFiles || initialAttachmentCount !== fields.length;
    };

    const handleBack = () => {
        const currentPath = window.location.pathname;
        const newPath = currentPath.replace(/\/attachments\/manage.*$/, '');
        navigate(newPath);
    };

    useEffect(() => {
        const fetchAttachments = async () => {
            try {
                setLoading(true);
                const response = await apiService.get('/attachments', {entityId});

                reset({attachments: []});

                const formattedAttachments = response.attachments.map(attachment => ({
                    file: null,
                    fileUrl: attachment.blob_url,
                    filename: attachment.filename,
                    category: attachment.attachment_category,
                    type: attachment.attachment_type,
                    id: attachment.id,
                    created_at: attachment.created_at,
                    created_by: attachment.created_by,
                    uploaded_at: attachment.uploaded_at,
                    uploaded_by: attachment.uploaded_by
                }));

                setInitialAttachmentCount(formattedAttachments.length);
                reset({attachments: formattedAttachments});
            } catch (err) {
                console.error('Error fetching attachments:', err);
                setError('Failed to fetch attachments.');
            } finally {
                setLoading(false);
            }
        };

        if (entityId) {
            fetchAttachments();
        }
    }, [entityId, reset]);

    const handleFileChange = (file, index) => {
        if (file) {
            setValue(`attachments.${index}.file`, file);
            setValue(`attachments.${index}.filename`, file.name);
            const category = getCategoryFromFileName(file.name);
            setValue(`attachments.${index}.category`, category);
            setValue(`attachments.${index}.type`, '');
        }
    };

    const addAttachment = () => {
        append({
            file: null,
            fileUrl: null,
            filename: '',
            category: '',
            type: '',
        });
    };

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const newAttachments = data.attachments.filter(att => att.file && !att.id);

            if (newAttachments.length > 0) {
                const formData = new FormData();
                formData.append('entity_id', entityId);
                formData.append('node_label', nodeLabel);

                newAttachments.forEach((attachment) => {
                    formData.append('files', attachment.file);
                    formData.append('filenames', attachment.filename);
                    formData.append('categories', attachment.category);
                    formData.append('types', attachment.type);
                });

                await apiService.post('/attachments/upload', formData);
            }

            handleBack();
        } catch (err) {
            console.error('Error saving attachments:', err);
            setError('Failed to save attachments. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <BFMTemplatePage component={

            <Box p={2}>
                <Paper elevation={3} sx={{p: 3, backgroundColor: '#1e1e1e', color: '#fff'}}>
                    <Typography variant="h5" mb={3}>
                        Manage Attachments
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add/>}
                        onClick={addAttachment}
                        sx={{mb: 3}}
                    >
                        Add Attachment
                    </Button>
                    {error && <Alert severity="error" sx={{mb: 3}}>{error}</Alert>}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((attachment, index) => {
                            const category = watch(`attachments.${index}.category`) || '';
                            const availableTypes = getSubTypesForCategory(category) || [];

                            return (
                                <Accordion
                                    key={attachment._id}
                                    defaultExpanded={!attachment.fileUrl}
                                    sx={{
                                        mb: 2,
                                        backgroundColor: '#2a2a2a',
                                        border: 'none',
                                        boxShadow: 'none',
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMore/>}
                                        sx={{
                                            '& .MuiAccordionSummary-content': {
                                                margin: '0',
                                                color: '#fff',
                                            }
                                        }}
                                    >
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}>
                                            <Typography>
                                                {watch(`attachments.${index}.filename`) || 'New Attachment'}
                                            </Typography>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                '& > *': {flexShrink: 0}
                                            }}>
                                                <Chip
                                                    label={category || 'Uncategorized'}
                                                    sx={{
                                                        backgroundColor: '#616161',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                    }}
                                                />
                                                {attachment.uploaded_by && (
                                                    <Typography variant="caption" color="text.secondary">
                                                        Uploaded by {attachment.uploaded_by}
                                                    </Typography>
                                                )}
                                                <Tooltip title="Remove attachment">
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            remove(index);
                                                        }}
                                                        sx={{
                                                            ml: 1,
                                                            color: 'error.main',
                                                            '&:hover': {
                                                                backgroundColor: 'error.light',
                                                                color: 'error.main',
                                                            }
                                                        }}
                                                    >
                                                        <Delete fontSize="small"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <Controller
                                                    name={`attachments.${index}.filename`}
                                                    control={control}
                                                    rules={{required: 'Filename is required'}}
                                                    render={({field, fieldState}) => (
                                                        <TextField
                                                            {...field}
                                                            label="File Name *"
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error?.message}
                                                            fullWidth
                                                            size="small"
                                                            sx={{mb: 2, backgroundColor: '#2a2a2a', color: '#fff'}}
                                                        />
                                                    )}
                                                />
                                                <Controller
                                                    name={`attachments.${index}.category`}
                                                    control={control}
                                                    render={({field}) => (
                                                        <TextField
                                                            {...field}
                                                            label="Category"
                                                            fullWidth
                                                            size="small"
                                                            sx={{mb: 2}}
                                                            disabled
                                                            InputProps={{
                                                                style: {
                                                                    backgroundColor: '#2a2a2a',
                                                                    color: '#fff',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                                {category && (
                                                    <Controller
                                                        name={`attachments.${index}.type`}
                                                        control={control}
                                                        rules={{required: 'Please select a type for this file'}}
                                                        render={({field, fieldState}) => (
                                                            <FormControl
                                                                fullWidth
                                                                size="small"
                                                                error={!!fieldState.error}
                                                            >
                                                                <InputLabel>Type *</InputLabel>
                                                                <Select
                                                                    {...field}
                                                                    label="Type *"
                                                                    sx={{backgroundColor: '#2a2a2a', color: '#fff'}}
                                                                >
                                                                    <MenuItem value="">
                                                                        <em>Select a type</em>
                                                                    </MenuItem>
                                                                    {availableTypes.map((type) => (
                                                                        <MenuItem key={type} value={type}>
                                                                            {type}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                                {fieldState.error && (
                                                                    <FormHelperText>
                                                                        {fieldState.error.message}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    />
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FileDropzone
                                                    onDrop={(file) => handleFileChange(file, index)}
                                                    file={watch(`attachments.${index}.file`)}
                                                    fileUrl={attachment.fileUrl}
                                                />
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                        <Box mt={3} display="flex" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleBack}
                                sx={{color: 'white', borderColor: 'white', '&:hover': {borderColor: 'gray'}}}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading || !hasChanges()}
                                sx={{
                                    backgroundColor: '#42a5f5',
                                    '&:hover': {
                                        backgroundColor: '#1e88e5',
                                    },
                                }}
                            >
                                {loading ? <CircularProgress size={24}/> : 'Save'}
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Box>
        }

        />
    );

};

export default AttachmentManager;