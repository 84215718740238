const countryOptions = [{'country': 'Afghanistan', 'alpha_2': 'AF', 'region': 'Asia', 'flag': '🇦🇫'},
    {'country': 'Albania', 'alpha_2': 'AL', 'region': 'Europe', 'flag': '🇦🇱'},
    {'country': 'Algeria', 'alpha_2': 'DZ', 'region': 'Africa', 'flag': '🇩🇿'},
    {'country': 'Andorra', 'alpha_2': 'AD', 'region': 'Europe', 'flag': '🇦🇩'},
    {'country': 'Angola', 'alpha_2': 'AO', 'region': 'Africa', 'flag': '🇦🇴'},
    {
        'country': 'Antigua and Barbuda',
        'alpha_2': 'AG',
        'region': 'Americas',
        'flag': '🇦🇬'
    },
    {'country': 'Argentina', 'alpha_2': 'AR', 'region': 'Americas', 'flag': '🇦🇷'},
    {'country': 'Armenia', 'alpha_2': 'AM', 'region': 'Asia', 'flag': '🇦🇲'},
    {'country': 'Australia', 'alpha_2': 'AU', 'region': 'Oceania', 'flag': '🇦🇺'},
    {'country': 'Austria', 'alpha_2': 'AT', 'region': 'Europe', 'flag': '🇦🇹'},
    {'country': 'Azerbaijan', 'alpha_2': 'AZ', 'region': 'Asia', 'flag': '🇦🇿'},
    {'country': 'Bahamas', 'alpha_2': 'BS', 'region': 'Americas', 'flag': '🇧🇸'},
    {'country': 'Bahrain', 'alpha_2': 'BH', 'region': 'Asia', 'flag': '🇧🇭'},
    {'country': 'Bangladesh', 'alpha_2': 'BD', 'region': 'Asia', 'flag': '🇧🇩'},
    {'country': 'Barbados', 'alpha_2': 'BB', 'region': 'Americas', 'flag': '🇧🇧'},
    {'country': 'Belarus', 'alpha_2': 'BY', 'region': 'Europe', 'flag': '🇧🇾'},
    {'country': 'Belgium', 'alpha_2': 'BE', 'region': 'Europe', 'flag': '🇧🇪'},
    {'country': 'Belize', 'alpha_2': 'BZ', 'region': 'Americas', 'flag': '🇧🇿'},
    {'country': 'Benin', 'alpha_2': 'BJ', 'region': 'Africa', 'flag': '🇧🇯'},
    {'country': 'Bhutan', 'alpha_2': 'BT', 'region': 'Asia', 'flag': '🇧🇹'},
    {'country': 'Bolivia', 'alpha_2': 'BO', 'region': 'Americas', 'flag': '🇧🇴'},
    {
        'country': 'Bosnia and Herzegovina',
        'alpha_2': '',
        'region': '',
        'flag': ''
    },
    {'country': 'Botswana', 'alpha_2': 'BW', 'region': 'Africa', 'flag': '🇧🇼'},
    {'country': 'Brazil', 'alpha_2': 'BR', 'region': 'Americas', 'flag': '🇧🇷'},
    {'country': 'Brunei', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Bulgaria', 'alpha_2': 'BG', 'region': 'Europe', 'flag': '🇧🇬'},
    {
        'country': 'Burkina Faso',
        'alpha_2': 'BF',
        'region': 'Africa',
        'flag': '🇧🇫'
    },
    {'country': 'Burundi', 'alpha_2': 'BI', 'region': 'Africa', 'flag': '🇧🇮'},
    {'country': 'Cambodia', 'alpha_2': 'KH', 'region': 'Asia', 'flag': '🇰🇭'},
    {'country': 'Cameroon', 'alpha_2': 'CM', 'region': 'Africa', 'flag': '🇨🇲'},
    {'country': 'Canada', 'alpha_2': 'CA', 'region': 'Americas', 'flag': '🇨🇦'},
    {'country': 'Cape Verde', 'alpha_2': '', 'region': '', 'flag': ''},
    {
        'country': 'Central African Republic',
        'alpha_2': 'CF',
        'region': 'Africa',
        'flag': '🇨🇫'
    },
    {'country': 'Chad', 'alpha_2': 'TD', 'region': 'Africa', 'flag': '🇹🇩'},
    {'country': 'Chile', 'alpha_2': 'CL', 'region': 'Americas', 'flag': '🇨🇱'},
    {'country': 'China', 'alpha_2': 'CN', 'region': 'Asia', 'flag': '🇨🇳'},
    {'country': 'Colombia', 'alpha_2': 'CO', 'region': 'Americas', 'flag': '🇨🇴'},
    {'country': 'Comoros', 'alpha_2': 'KM', 'region': 'Africa', 'flag': '🇰🇲'},
    {
        'country': 'Democratic Republic of Congo',
        'alpha_2': '',
        'region': '',
        'flag': ''
    },
    {'country': 'Republic of Congo', 'alpha_2': '', 'region': '', 'flag': ''},
    {
        'country': 'Costa Rica',
        'alpha_2': 'CR',
        'region': 'Americas',
        'flag': '🇨🇷'
    },
    {'country': 'Croatia', 'alpha_2': 'HR', 'region': 'Europe', 'flag': '🇭🇷'},
    {'country': 'Cuba', 'alpha_2': 'CU', 'region': 'Americas', 'flag': '🇨🇺'},
    {'country': 'Cyprus', 'alpha_2': 'CY', 'region': 'Asia', 'flag': '🇨🇾'},
    {
        'country': 'Czech Republic',
        'alpha_2': 'CZ',
        'region': 'Europe',
        'flag': '🇨🇿'
    },
    {'country': 'Denmark', 'alpha_2': 'DK', 'region': 'Europe', 'flag': '🇩🇰'},
    {'country': 'Djibouti', 'alpha_2': 'DJ', 'region': 'Africa', 'flag': '🇩🇯'},
    {'country': 'Dominica', 'alpha_2': 'DM', 'region': 'Americas', 'flag': '🇩🇲'},
    {
        'country': 'Dominican Republic',
        'alpha_2': 'DO',
        'region': 'Americas',
        'flag': '🇩🇴'
    },
    {'country': 'East Timor', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Ecuador', 'alpha_2': 'EC', 'region': 'Americas', 'flag': '🇪🇨'},
    {'country': 'Egypt', 'alpha_2': 'EG', 'region': 'Africa', 'flag': '🇪🇬'},
    {
        'country': 'El Salvador',
        'alpha_2': 'SV',
        'region': 'Americas',
        'flag': '🇸🇻'
    },
    {
        'country': 'Equatorial Guinea',
        'alpha_2': 'GQ',
        'region': 'Africa',
        'flag': '🇬🇶'
    },
    {'country': 'Eritrea', 'alpha_2': 'ER', 'region': 'Africa', 'flag': '🇪🇷'},
    {'country': 'Estonia', 'alpha_2': 'EE', 'region': 'Europe', 'flag': '🇪🇪'},
    {'country': 'Ethiopia', 'alpha_2': 'ET', 'region': 'Africa', 'flag': '🇪🇹'},
    {'country': 'Fiji', 'alpha_2': 'FJ', 'region': 'Oceania', 'flag': '🇫🇯'},
    {'country': 'Finland', 'alpha_2': 'FI', 'region': 'Europe', 'flag': '🇫🇮'},
    {'country': 'France', 'alpha_2': 'FR', 'region': 'Europe', 'flag': '🇫🇷'},
    {'country': 'Gabon', 'alpha_2': 'GA', 'region': 'Africa', 'flag': '🇬🇦'},
    {'country': 'The Gambia', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Georgia', 'alpha_2': 'GE', 'region': 'Asia', 'flag': '🇬🇪'},
    {'country': 'Germany', 'alpha_2': 'DE', 'region': 'Europe', 'flag': '🇩🇪'},
    {'country': 'Ghana', 'alpha_2': 'GH', 'region': 'Africa', 'flag': '🇬🇭'},
    {'country': 'Greece', 'alpha_2': 'GR', 'region': 'Europe', 'flag': '🇬🇷'},
    {'country': 'Grenada', 'alpha_2': 'GD', 'region': 'Americas', 'flag': '🇬🇩'},
    {'country': 'Guatemala', 'alpha_2': 'GT', 'region': 'Americas', 'flag': '🇬🇹'},
    {'country': 'Guinea', 'alpha_2': 'GN', 'region': 'Africa', 'flag': '🇬🇳'},
    {'country': 'Guinea-Bissau', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Guyana', 'alpha_2': 'GY', 'region': 'Americas', 'flag': '🇬🇾'},
    {'country': 'Haiti', 'alpha_2': 'HT', 'region': 'Americas', 'flag': '🇭🇹'},
    {'country': 'Honduras', 'alpha_2': 'HN', 'region': 'Americas', 'flag': '🇭🇳'},
    {'country': 'Hungary', 'alpha_2': 'HU', 'region': 'Europe', 'flag': '🇭🇺'},
    {'country': 'Iceland', 'alpha_2': 'IS', 'region': 'Europe', 'flag': '🇮🇸'},
    {'country': 'India', 'alpha_2': 'IN', 'region': 'Asia', 'flag': '🇮🇳'},
    {'country': 'Indonesia', 'alpha_2': 'ID', 'region': 'Asia', 'flag': '🇮🇩'},
    {'country': 'Iran', 'alpha_2': 'IR', 'region': 'Asia', 'flag': '🇮🇷'},
    {'country': 'Iraq', 'alpha_2': 'IQ', 'region': 'Asia', 'flag': '🇮🇶'},
    {'country': 'Ireland', 'alpha_2': 'IE', 'region': 'Europe', 'flag': '🇮🇪'},
    {'country': 'Israel', 'alpha_2': 'IL', 'region': 'Asia', 'flag': '🇮🇱'},
    {'country': 'Italy', 'alpha_2': 'IT', 'region': 'Europe', 'flag': '🇮🇹'},
    {'country': 'Ivory Coast', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Jamaica', 'alpha_2': 'JM', 'region': 'Americas', 'flag': '🇯🇲'},
    {'country': 'Japan', 'alpha_2': 'JP', 'region': 'Asia', 'flag': '🇯🇵'},
    {'country': 'Jordan', 'alpha_2': 'JO', 'region': 'Asia', 'flag': '🇯🇴'},
    {'country': 'Kazakhstan', 'alpha_2': 'KZ', 'region': 'Asia', 'flag': '🇰🇿'},
    {'country': 'Kenya', 'alpha_2': 'KE', 'region': 'Africa', 'flag': '🇰🇪'},
    {'country': 'Kiribati', 'alpha_2': 'KI', 'region': 'Oceania', 'flag': '🇰🇮'},
    {'country': 'North Korea', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'South Korea', 'alpha_2': 'KP', 'region': 'Asia', 'flag': '🇰🇵'},
    {'country': 'Kuwait', 'alpha_2': 'KW', 'region': 'Asia', 'flag': '🇰🇼'},
    {'country': 'Kyrgyzstan', 'alpha_2': 'KG', 'region': 'Asia', 'flag': '🇰🇬'},
    {'country': 'Laos', 'alpha_2': 'LA', 'region': 'Asia', 'flag': '🇱🇦'},
    {'country': 'Latvia', 'alpha_2': 'LV', 'region': 'Europe', 'flag': '🇱🇻'},
    {'country': 'Lebanon', 'alpha_2': 'LB', 'region': 'Asia', 'flag': '🇱🇧'},
    {'country': 'Lesotho', 'alpha_2': 'LS', 'region': 'Africa', 'flag': '🇱🇸'},
    {'country': 'Liberia', 'alpha_2': 'LR', 'region': 'Africa', 'flag': '🇱🇷'},
    {'country': 'Libya', 'alpha_2': 'LY', 'region': 'Africa', 'flag': '🇱🇾'},
    {
        'country': 'Liechtenstein',
        'alpha_2': 'LI',
        'region': 'Europe',
        'flag': '🇱🇮'
    },
    {'country': 'Lithuania', 'alpha_2': 'LT', 'region': 'Europe', 'flag': '🇱🇹'},
    {'country': 'Luxembourg', 'alpha_2': 'LU', 'region': 'Europe', 'flag': '🇱🇺'},
    {'country': 'Macedonia', 'alpha_2': 'MK', 'region': 'Europe', 'flag': '🇲🇰'},
    {'country': 'Madagascar', 'alpha_2': 'MG', 'region': 'Africa', 'flag': '🇲🇬'},
    {'country': 'Malawi', 'alpha_2': 'MW', 'region': 'Africa', 'flag': '🇲🇼'},
    {'country': 'Malaysia', 'alpha_2': 'MY', 'region': 'Asia', 'flag': '🇲🇾'},
    {'country': 'Maldives', 'alpha_2': 'MV', 'region': 'Asia', 'flag': '🇲🇻'},
    {'country': 'Mali', 'alpha_2': 'ML', 'region': 'Africa', 'flag': '🇲🇱'},
    {'country': 'Malta', 'alpha_2': 'MT', 'region': 'Europe', 'flag': '🇲🇹'},
    {
        'country': 'Marshall Islands',
        'alpha_2': 'MH',
        'region': 'Oceania',
        'flag': '🇲🇭'
    },
    {'country': 'Mauritania', 'alpha_2': 'MR', 'region': 'Africa', 'flag': '🇲🇷'},
    {'country': 'Mauritius', 'alpha_2': 'MU', 'region': 'Africa', 'flag': '🇲🇺'},
    {'country': 'Mexico', 'alpha_2': 'MX', 'region': 'Americas', 'flag': '🇲🇽'},
    {
        'country': 'Federated States of Micronesia',
        'alpha_2': '',
        'region': '',
        'flag': ''
    },
    {'country': 'Moldova', 'alpha_2': 'MD', 'region': 'Europe', 'flag': '🇲🇩'},
    {'country': 'Monaco', 'alpha_2': 'MC', 'region': 'Europe', 'flag': '🇲🇨'},
    {'country': 'Mongolia', 'alpha_2': 'MN', 'region': 'Asia', 'flag': '🇲🇳'},
    {'country': 'Montenegro', 'alpha_2': 'ME', 'region': 'Europe', 'flag': '🇲🇪'},
    {'country': 'Morocco', 'alpha_2': 'MA', 'region': 'Africa', 'flag': '🇲🇦'},
    {'country': 'Mozambique', 'alpha_2': 'MZ', 'region': 'Africa', 'flag': '🇲🇿'},
    {'country': 'Myanmar', 'alpha_2': 'MM', 'region': 'Asia', 'flag': '🇲🇲'},
    {'country': 'Namibia', 'alpha_2': '', 'region': 'Africa', 'flag': ''},
    {'country': 'Nauru', 'alpha_2': 'NR', 'region': 'Oceania', 'flag': '🇳🇷'},
    {'country': 'Nepal', 'alpha_2': 'NP', 'region': 'Asia', 'flag': '🇳🇵'},
    {'country': 'Netherlands', 'alpha_2': 'NL', 'region': 'Europe', 'flag': '🇳🇱'},
    {
        'country': 'New Zealand',
        'alpha_2': 'NZ',
        'region': 'Oceania',
        'flag': '🇳🇿'
    },
    {'country': 'Nicaragua', 'alpha_2': 'NI', 'region': 'Americas', 'flag': '🇳🇮'},
    {'country': 'Niger', 'alpha_2': 'NE', 'region': 'Africa', 'flag': '🇳🇪'},
    {'country': 'Nigeria', 'alpha_2': 'NG', 'region': 'Africa', 'flag': '🇳🇬'},
    {'country': 'Norway', 'alpha_2': 'NO', 'region': 'Europe', 'flag': '🇳🇴'},
    {'country': 'Oman', 'alpha_2': 'OM', 'region': 'Asia', 'flag': '🇴🇲'},
    {'country': 'Pakistan', 'alpha_2': 'PK', 'region': 'Asia', 'flag': '🇵🇰'},
    {'country': 'Palau', 'alpha_2': 'PW', 'region': 'Oceania', 'flag': '🇵🇼'},
    {'country': 'Palestine', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Panama', 'alpha_2': 'PA', 'region': 'Americas', 'flag': '🇵🇦'},
    {
        'country': 'Papua New Guinea',
        'alpha_2': 'PG',
        'region': 'Oceania',
        'flag': '🇵🇬'
    },
    {'country': 'Paraguay', 'alpha_2': 'PY', 'region': 'Americas', 'flag': '🇵🇾'},
    {'country': 'Peru', 'alpha_2': 'PE', 'region': 'Americas', 'flag': '🇵🇪'},
    {'country': 'Philippines', 'alpha_2': 'PH', 'region': 'Asia', 'flag': '🇵🇭'},
    {'country': 'Poland', 'alpha_2': 'PL', 'region': 'Europe', 'flag': '🇵🇱'},
    {'country': 'Portugal', 'alpha_2': 'PT', 'region': 'Europe', 'flag': '🇵🇹'},
    {'country': 'Qatar', 'alpha_2': 'QA', 'region': 'Asia', 'flag': '🇶🇦'},
    {'country': 'Romania', 'alpha_2': 'RO', 'region': 'Europe', 'flag': '🇷🇴'},
    {'country': 'Russia', 'alpha_2': 'RU', 'region': 'Europe', 'flag': '🇷🇺'},
    {'country': 'Rwanda', 'alpha_2': 'RW', 'region': 'Africa', 'flag': '🇷🇼'},
    {
        'country': 'Saint Kitts and Nevis',
        'alpha_2': 'KN',
        'region': 'Americas',
        'flag': '🇰🇳'
    },
    {
        'country': 'Saint Lucia',
        'alpha_2': 'LC',
        'region': 'Americas',
        'flag': '🇱🇨'
    },
    {
        'country': 'Saint Vincent and Grenadines',
        'alpha_2': '',
        'region': '',
        'flag': ''
    },
    {'country': 'Samoa', 'alpha_2': 'WS', 'region': 'Oceania', 'flag': '🇼🇸'},
    {'country': 'San Marino', 'alpha_2': 'SM', 'region': 'Europe', 'flag': '🇸🇲'},
    {
        'country': 'Sao Tome and Principe',
        'alpha_2': 'ST',
        'region': 'Africa',
        'flag': '🇸🇹'
    },
    {'country': 'Saudi Arabia', 'alpha_2': 'SA', 'region': 'Asia', 'flag': '🇸🇦'},
    {'country': 'Senegal', 'alpha_2': 'SN', 'region': 'Africa', 'flag': '🇸🇳'},
    {'country': 'Serbia', 'alpha_2': 'RS', 'region': 'Europe', 'flag': '🇷🇸'},
    {'country': 'Seychelles', 'alpha_2': 'SC', 'region': 'Africa', 'flag': '🇸🇨'},
    {
        'country': 'Sierra Leone',
        'alpha_2': 'SL',
        'region': 'Africa',
        'flag': '🇸🇱'
    },
    {'country': 'Singapore', 'alpha_2': 'SG', 'region': 'Asia', 'flag': '🇸🇬'},
    {'country': 'Slovakia', 'alpha_2': 'SK', 'region': 'Europe', 'flag': '🇸🇰'},
    {'country': 'Slovenia', 'alpha_2': 'SI', 'region': 'Europe', 'flag': '🇸🇮'},
    {
        'country': 'Solomon Islands',
        'alpha_2': 'SB',
        'region': 'Oceania',
        'flag': '🇸🇧'
    },
    {'country': 'Somalia', 'alpha_2': 'SO', 'region': 'Africa', 'flag': '🇸🇴'},
    {
        'country': 'South Africa',
        'alpha_2': 'ZA',
        'region': 'Africa',
        'flag': '🇿🇦'
    },
    {'country': 'South Sudan', 'alpha_2': 'SS', 'region': 'Africa', 'flag': '🇸🇸'},
    {'country': 'Spain', 'alpha_2': 'ES', 'region': 'Europe', 'flag': '🇪🇸'},
    {'country': 'Sri Lanka', 'alpha_2': 'LK', 'region': 'Asia', 'flag': '🇱🇰'},
    {'country': 'Sudan', 'alpha_2': 'SD', 'region': 'Africa', 'flag': '🇸🇩'},
    {'country': 'Suriname', 'alpha_2': 'SR', 'region': 'Americas', 'flag': '🇸🇷'},
    {'country': 'Swaziland', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Sweden', 'alpha_2': 'SE', 'region': 'Europe', 'flag': '🇸🇪'},
    {'country': 'Switzerland', 'alpha_2': 'CH', 'region': 'Europe', 'flag': '🇨🇭'},
    {'country': 'Syria', 'alpha_2': 'SY', 'region': 'Asia', 'flag': '🇸🇾'},
    {'country': 'Tajikistan', 'alpha_2': 'TJ', 'region': 'Asia', 'flag': '🇹🇯'},
    {'country': 'Tanzania', 'alpha_2': 'TZ', 'region': 'Africa', 'flag': '🇹🇿'},
    {'country': 'Thailand', 'alpha_2': 'TH', 'region': 'Asia', 'flag': '🇹🇭'},
    {'country': 'Togo', 'alpha_2': 'TG', 'region': 'Africa', 'flag': '🇹🇬'},
    {'country': 'Tonga', 'alpha_2': 'TO', 'region': 'Oceania', 'flag': '🇹🇴'},
    {
        'country': 'Trinidad and Tobago',
        'alpha_2': 'TT',
        'region': 'Americas',
        'flag': '🇹🇹'
    },
    {'country': 'Tunisia', 'alpha_2': 'TN', 'region': 'Africa', 'flag': '🇹🇳'},
    {'country': 'Turkey', 'alpha_2': 'TR', 'region': 'Asia', 'flag': '🇹🇷'},
    {'country': 'Turkmenistan', 'alpha_2': 'TM', 'region': 'Asia', 'flag': '🇹🇲'},
    {'country': 'Tuvalu', 'alpha_2': 'TV', 'region': 'Oceania', 'flag': '🇹🇻'},
    {'country': 'Uganda', 'alpha_2': 'UG', 'region': 'Africa', 'flag': '🇺🇬'},
    {'country': 'Ukraine', 'alpha_2': 'UA', 'region': 'Europe', 'flag': '🇺🇦'},
    {
        'country': 'United Arab Emirates',
        'alpha_2': 'AE',
        'region': 'Asia',
        'flag': '🇦🇪'
    },
    {
        'country': 'United Kingdom',
        'alpha_2': 'GB',
        'region': 'Europe',
        'flag': '🇬🇧'
    },
    {
        'country': 'United States',
        'alpha_2': 'US',
        'region': 'Americas',
        'flag': '🇺🇸'
    },
    {'country': 'Uruguay', 'alpha_2': 'UY', 'region': 'Americas', 'flag': '🇺🇾'},
    {'country': 'Uzbekistan', 'alpha_2': 'UZ', 'region': 'Asia', 'flag': '🇺🇿'},
    {'country': 'Vanuatu', 'alpha_2': 'VU', 'region': 'Oceania', 'flag': '🇻🇺'},
    {'country': 'Vatican City', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Venezuela', 'alpha_2': 'VE', 'region': 'Americas', 'flag': '🇻🇪'},
    {'country': 'Vietnam', 'alpha_2': 'VN', 'region': 'Asia', 'flag': '🇻🇳'},
    {'country': 'Yemen', 'alpha_2': 'YE', 'region': 'Asia', 'flag': '🇾🇪'},
    {'country': 'Zambia', 'alpha_2': 'ZM', 'region': 'Africa', 'flag': '🇿🇲'},
    {'country': 'Zimbabwe', 'alpha_2': 'ZW', 'region': 'Africa', 'flag': '🇿🇼'},
    {'country': 'Abkhazia', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Artsakh', 'alpha_2': '', 'region': '', 'flag': ''},
    {
        'country': 'Cook Islands',
        'alpha_2': 'CK',
        'region': 'Oceania',
        'flag': '🇨🇰'
    },
    {'country': 'Kosovo', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Niue', 'alpha_2': 'NU', 'region': 'Oceania', 'flag': '🇳🇺'},
    {
        'country': 'Turkish Republic of Northern Cyprus',
        'alpha_2': '',
        'region': '',
        'flag': ''
    },
    {
        'country': 'Sahrawi Arab Democratic Republic',
        'alpha_2': '',
        'region': '',
        'flag': ''
    },
    {'country': 'Somaliland', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'South Ossetia', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Republic of China', 'alpha_2': '', 'region': '', 'flag': ''},
    {'country': 'Transnistria', 'alpha_2': '', 'region': '', 'flag': ''},
    {
        'country': 'Åland Islands',
        'alpha_2': 'AX',
        'region': 'Europe',
        'flag': '🇦🇽'
    },
    {
        'country': 'American Samoa',
        'alpha_2': 'AS',
        'region': 'Oceania',
        'flag': '🇦🇸'
    },
    {'country': 'Anguilla', 'alpha_2': 'AI', 'region': 'Americas', 'flag': '🇦🇮'},
    {'country': 'Antarctica', 'alpha_2': 'AQ', 'region': '', 'flag': '🇦🇶'},
    {'country': 'Aruba', 'alpha_2': 'AW', 'region': 'Americas', 'flag': '🇦🇼'},
    {'country': 'Bermuda', 'alpha_2': 'BM', 'region': 'Americas', 'flag': '🇧🇲'},
    {
        'country': 'Bonaire, Sint Eustatius and Saba',
        'alpha_2': 'BQ',
        'region': 'Americas',
        'flag': '🇧🇶'
    },
    {
        'country': 'Bosnia And Herzegovina',
        'alpha_2': 'BA',
        'region': 'Europe',
        'flag': '🇧🇦'
    },
    {
        'country': 'Bouvet Island',
        'alpha_2': 'BV',
        'region': 'Americas',
        'flag': '🇧🇻'
    },
    {
        'country': 'British Indian Ocean Territory',
        'alpha_2': 'IO',
        'region': 'Africa',
        'flag': '🇮🇴'
    },
    {
        'country': 'Brunei Darussalam',
        'alpha_2': 'BN',
        'region': 'Asia',
        'flag': '🇧🇳'
    },
    {'country': 'Cabo Verde', 'alpha_2': 'CV', 'region': 'Africa', 'flag': '🇨🇻'},
    {
        'country': 'Cayman Islands',
        'alpha_2': 'KY',
        'region': 'Americas',
        'flag': '🇰🇾'
    },
    {
        'country': 'Christmas Island',
        'alpha_2': 'CX',
        'region': 'Oceania',
        'flag': '🇨🇽'
    },
    {
        'country': 'Cocos (Keeling) Islands',
        'alpha_2': 'CC',
        'region': 'Oceania',
        'flag': '🇨🇨'
    },
    {'country': 'Congo', 'alpha_2': 'CG', 'region': 'Africa', 'flag': '🇨🇬'},
    {
        'country': 'Congo (Democratic Republic Of The)',
        'alpha_2': 'CD',
        'region': 'Africa',
        'flag': '🇨🇩'
    },
    {
        'country': "Côte D'Ivoire",
        'alpha_2': 'CI',
        'region': 'Africa',
        'flag': '🇨🇮'
    },
    {'country': 'Curaçao', 'alpha_2': 'CW', 'region': 'Americas', 'flag': '🇨🇼'},
    {'country': 'Eswatini', 'alpha_2': 'SZ', 'region': 'Africa', 'flag': '🇸🇿'},
    {
        'country': 'Falkland Islands (Malvinas)',
        'alpha_2': 'FK',
        'region': 'Americas',
        'flag': '🇫🇰'
    },
    {
        'country': 'Faroe Islands',
        'alpha_2': 'FO',
        'region': 'Europe',
        'flag': '🇫🇴'
    },
    {
        'country': 'French Guiana',
        'alpha_2': 'GF',
        'region': 'Americas',
        'flag': '🇬🇫'
    },
    {
        'country': 'French Polynesia',
        'alpha_2': 'PF',
        'region': 'Oceania',
        'flag': '🇵🇫'
    },
    {
        'country': 'French Southern Territories',
        'alpha_2': 'TF',
        'region': 'Africa',
        'flag': '🇹🇫'
    },
    {'country': 'Gambia', 'alpha_2': 'GM', 'region': 'Africa', 'flag': '🇬🇲'},
    {'country': 'Gibraltar', 'alpha_2': 'GI', 'region': 'Europe', 'flag': '🇬🇮'},
    {'country': 'Greenland', 'alpha_2': 'GL', 'region': 'Americas', 'flag': '🇬🇱'},
    {
        'country': 'Guadeloupe',
        'alpha_2': 'GP',
        'region': 'Americas',
        'flag': '🇬🇵'
    },
    {'country': 'Guam', 'alpha_2': 'GU', 'region': 'Oceania', 'flag': '🇬🇺'},
    {'country': 'Guernsey', 'alpha_2': 'GG', 'region': 'Europe', 'flag': '🇬🇬'},
    {
        'country': 'Guinea Bissau',
        'alpha_2': 'GW',
        'region': 'Africa',
        'flag': '🇬🇼'
    },
    {
        'country': 'Heard Island and McDonald Islands',
        'alpha_2': 'HM',
        'region': 'Oceania',
        'flag': '🇭🇲'
    },
    {'country': 'Holy See', 'alpha_2': 'VA', 'region': 'Europe', 'flag': '🇻🇦'},
    {'country': 'Hong Kong', 'alpha_2': 'HK', 'region': 'Asia', 'flag': '🇭🇰'},
    {'country': 'Isle of Man', 'alpha_2': 'IM', 'region': 'Europe', 'flag': '🇮🇲'},
    {'country': 'Jersey', 'alpha_2': 'JE', 'region': 'Europe', 'flag': '🇯🇪'},
    {
        'country': 'Korea, Republic of',
        'alpha_2': 'KR',
        'region': 'Asia',
        'flag': '🇰🇷'
    },
    {'country': 'Macao', 'alpha_2': 'MO', 'region': 'Asia', 'flag': '🇲🇴'},
    {
        'country': 'Martinique',
        'alpha_2': 'MQ',
        'region': 'Americas',
        'flag': '🇲🇶'
    },
    {'country': 'Mayotte', 'alpha_2': 'YT', 'region': 'Africa', 'flag': '🇾🇹'},
    {
        'country': 'Micronesia (Federated States of)',
        'alpha_2': 'FM',
        'region': 'Oceania',
        'flag': '🇫🇲'
    },
    {
        'country': 'Montserrat',
        'alpha_2': 'MS',
        'region': 'Americas',
        'flag': '🇲🇸'
    },
    {
        'country': 'New Caledonia',
        'alpha_2': 'NC',
        'region': 'Oceania',
        'flag': '🇳🇨'
    },
    {
        'country': 'Norfolk Island',
        'alpha_2': 'NF',
        'region': 'Oceania',
        'flag': '🇳🇫'
    },
    {
        'country': 'Northern Mariana Islands',
        'alpha_2': 'MP',
        'region': 'Oceania',
        'flag': '🇲🇵'
    },
    {
        'country': 'Palestine, State of',
        'alpha_2': 'PS',
        'region': 'Asia',
        'flag': '🇵🇸'
    },
    {'country': 'Pitcairn', 'alpha_2': 'PN', 'region': 'Oceania', 'flag': '🇵🇳'},
    {
        'country': 'Puerto Rico',
        'alpha_2': 'PR',
        'region': 'Americas',
        'flag': '🇵🇷'
    },
    {'country': 'Réunion', 'alpha_2': 'RE', 'region': 'Africa', 'flag': '🇷🇪'},
    {
        'country': 'Saint Barthélemy',
        'alpha_2': 'BL',
        'region': 'Americas',
        'flag': '🇧🇱'
    },
    {
        'country': 'Saint Helena, Ascension and Tristan da Cunha',
        'alpha_2': 'SH',
        'region': 'Africa',
        'flag': '🇸🇭'
    },
    {
        'country': 'Saint Martin (French part)',
        'alpha_2': 'MF',
        'region': 'Americas',
        'flag': '🇲🇫'
    },
    {
        'country': 'Saint Pierre and Miquelon',
        'alpha_2': 'PM',
        'region': 'Americas',
        'flag': '🇵🇲'
    },
    {
        'country': 'Saint Vincent and the Grenadines',
        'alpha_2': 'VC',
        'region': 'Americas',
        'flag': '🇻🇨'
    },
    {
        'country': 'Sint Maarten (Dutch part)',
        'alpha_2': 'SX',
        'region': 'Americas',
        'flag': '🇸🇽'
    },
    {
        'country': 'South Georgia and the South Sandwich Islands',
        'alpha_2': 'GS',
        'region': 'Americas',
        'flag': '🇬🇸'
    },
    {
        'country': 'Svalbard and Jan Mayen',
        'alpha_2': 'SJ',
        'region': 'Europe',
        'flag': '🇸🇯'
    },
    {'country': 'Taiwan', 'alpha_2': 'TW', 'region': 'Asia', 'flag': '🇹🇼'},
    {'country': 'Timor-Leste', 'alpha_2': 'TL', 'region': 'Asia', 'flag': '🇹🇱'},
    {'country': 'Tokelau', 'alpha_2': 'TK', 'region': 'Oceania', 'flag': '🇹🇰'},
    {
        'country': 'Turks and Caicos Islands',
        'alpha_2': 'TC',
        'region': 'Americas',
        'flag': '🇹🇨'
    },
    {
        'country': 'United States Minor Outlying Islands',
        'alpha_2': 'UM',
        'region': 'Oceania',
        'flag': '🇺🇲'
    },
    {
        'country': 'Virgin Islands (British)',
        'alpha_2': 'VG',
        'region': 'Americas',
        'flag': '🇻🇬'
    },
    {
        'country': 'Virgin Islands (U.S.)',
        'alpha_2': 'VI',
        'region': 'Americas',
        'flag': '🇻🇮'
    },
    {
        'country': 'Wallis and Futuna',
        'alpha_2': 'WF',
        'region': 'Oceania',
        'flag': '🇼🇫'
    },
    {
        'country': 'Western Sahara',
        'alpha_2': 'EH',
        'region': 'Africa',
        'flag': '🇪🇭'
    }]
export {countryOptions}