import { BFMTemplatePage } from "./BFMTemplatePage";
import { Typography } from "@mui/material";

export const Home = () => {
    return (
        <BFMTemplatePage
            component={
                <>
                    <Typography variant="h2">
                        Welcome to the RMS.
                    </Typography>
                    <Typography>
                        Please use the side panel or search bar to get started...
                    </Typography>
                </>
            }
        />
    );
};