import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Box,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from "@mui/material";
import {initializeAuth, signOutUser} from "../services/authService";
import {ApiRouter} from "./ApiRouter";
import SignInScreen from "./Auth/SignInScreen";
import Navbar from "./navigation/Navbar";
import BreadcrumbsNav from "./BreadcrumbsNav";

const INACTIVITY_TIMEOUT = 10 * 60 * 1000;
const WARNING_BEFORE_TIMEOUT = 60 * 1000;

function AppContent() {
    const dispatch = useDispatch();
    const {user, loading} = useSelector((state) => state.user);
    const [showWarning, setShowWarning] = useState(false);

    useEffect(() => {
        initializeAuth(dispatch);
    }, [dispatch]);

    useEffect(() => {
        let timeoutId;
        let warningTimeoutId;

        const handleUserActivity = () => {
            resetTimeout();
        };

        const resetTimeout = () => {
            clearTimeout(timeoutId);
            clearTimeout(warningTimeoutId);
            setShowWarning(false); // Hide warning if user interacts

            warningTimeoutId = setTimeout(() => {
                setShowWarning(true); // Show warning dialog
            }, INACTIVITY_TIMEOUT - WARNING_BEFORE_TIMEOUT);

            timeoutId = setTimeout(() => {
                console.log("Session expired due to inactivity.");
                signOutUser(dispatch);
            }, INACTIVITY_TIMEOUT);
        };

        window.addEventListener("mousemove", handleUserActivity);
        window.addEventListener("keydown", handleUserActivity);
        window.addEventListener("touchstart", handleUserActivity);

        resetTimeout();

        return () => {
            clearTimeout(timeoutId);
            clearTimeout(warningTimeoutId);
            window.removeEventListener("mousemove", handleUserActivity);
            window.removeEventListener("keydown", handleUserActivity);
            window.removeEventListener("touchstart", handleUserActivity);
        };
    }, [dispatch]);

    const handleExtendSession = () => {
        setShowWarning(false);
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress/>
            </Box>
        );
    }

    return user ? (
        <>
            <Dialog open={showWarning} onClose={handleExtendSession}>
                <DialogTitle>Session Expiring</DialogTitle>
                <DialogContent>
                    Your session is about to expire due to inactivity. Would you like to stay signed in?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleExtendSession} color="primary">
                        Stay Signed In
                    </Button>
                    <Button
                        onClick={() => {
                            setShowWarning(false);
                            signOutUser(dispatch);
                        }}
                        color="secondary"
                    >
                        Sign Out
                    </Button>
                </DialogActions>
            </Dialog>

            <Box display="flex" flexDirection="column" minHeight="100vh">
                <Navbar/>
                <BreadcrumbsNav />

                <Box component="main" flexGrow={1} mt={8} paddingLeft={'11vw'} paddingRight={'11vw'}>
                    <Container maxWidth="xl">

                        <ApiRouter/>
                    </Container>
                </Box>
            </Box>
        </>
    ) : (
        <SignInScreen/>
    );
}

export default AppContent;