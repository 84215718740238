import React, { useState } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { DownloadSimple } from '@phosphor-icons/react';
import apiService from '../../api/apiService';

const CovenantReportDownload = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const convertToCSV = (data) => {
        // Define headers based on the data structure
        const headers = [
            'business_entity',
            'title',
            'song_code',
            'name',
            'share',
            'right_type',
            'storage_location'
        ];

        // Create CSV header row
        let csv = headers.join(',') + '\n';

        // Add data rows
        data.forEach(item => {
            const row = headers.map(header => {
                // Handle null values and escape commas in values
                const value = item[header] === null ? '' : item[header];
                return `"${value}"`;
            });
            csv += row.join(',') + '\n';
        });

        return csv;
    };

    const downloadReport = async () => {
        setLoading(true);
        setError(null);

        try {
            // Fetch data from API
            const response = await apiService.get('covenant-report');

            // Convert the response data to CSV
            const csvContent = convertToCSV(response);

            // Create blob and trigger download
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `covenant-report-${new Date().toISOString().split('T')[0]}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error('Error downloading report:', err);
            setError('Failed to download report. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                Covenant Report Download
            </Typography>

            {error && (
                <Typography color="error" gutterBottom>
                    {error}
                </Typography>
            )}

            <Button
                variant="contained"
                color="primary"
                startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <DownloadSimple size={20} />}
                onClick={downloadReport}
                disabled={loading}
            >
                {loading ? 'Downloading...' : 'Download Covenant Report'}
            </Button>
        </Box>
    );
};

export default CovenantReportDownload;