// store/sessionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isActive: true,
    timeout: 600000,
};

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        resetSession: (state) => {
            state.isActive = true;
        },
        timeoutSession: (state) => {
            state.isActive = false;
        },
    },
});

export const { resetSession, timeoutSession } = sessionSlice.actions;
export default sessionSlice.reducer;