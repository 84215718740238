// src/pages/BusinessEntityCreate.js

import React from 'react';
import GenericEntityCreate from "../../components/forms/GenericEntityCreate";

const BusinessEntityCreate = () => {
    const schema = [
        {
            name: 'name',
            type: 'text',
            label: 'Business Entity Name',
            placeholder: 'Enter business entity name',
            required: true,
            default: '',
        },
    ];

    return (
        <GenericEntityCreate
            title="Create Business Entity"
            endpoint="/business-entities"
            schema={schema}
            returnUrl="/business-entities"
        />
    );
};

export default BusinessEntityCreate;