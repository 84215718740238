// RecordingsView.js
import React, {useMemo, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, FormProvider} from 'react-hook-form';
import {Handshake, Paperclip, Notepad, Gear} from "@phosphor-icons/react";

import GenericEntityView from '../../components/data/GenericEntityView';
import {BFMTemplatePage} from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import {RightsFormContainer} from '../../components/forms/RightsFormContainer';
import {EnhancedRightsForm} from '../../components/forms/EnhancedRightsForm';
import {fetchRecordingById, updateRecording} from "../../store/recordingSlice";
import RightsForm from "../../components/forms/RightsForm";

const RECORDING_SCHEMA = [
    {dataKey: 'id', label: 'Recording ID', editable: false},
    {dataKey: 'track_name', label: 'Parent Track', editable: true},
    {dataKey: 'isrc', label: 'ISRC', editable: true, titleKey: true},
    {dataKey: 'artist_name', label: 'Recorded By', editable: true}
];

const RecordingsView = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {item: recording, loading, error} = useSelector((state) => state.recordings);

    const methods = useForm({
        defaultValues: {
            id: '',
            track_name: '',
            isrc: '',
            artist_name: '',
            status: '',
            created_at: '',
            updated_at: '',
            created_by: '',
            updated_by: '',
        },
    });

    useEffect(() => {
        if (recording) {
            methods.reset({
                id: recording.id || '',
                track_name: recording.track_name || '',
                isrc: recording.isrc || '',
                artist_name: recording.artist_name || '',
                status: recording.status || '',
                created_at: recording.created_at || '',
                updated_by: recording.updated_by || '',
                updated_at: recording.updated_at || '',
                created_by: recording.created_by || '',
                is_deleted: recording.is_deleted || false,
                create_method: recording.create_method || '',
                version: recording.version || null
            });
        }
    }, [recording, methods.reset]);

    const fetchData = useCallback(async (id) => {
        return dispatch(fetchRecordingById(id));
    }, [dispatch]);

    const updateData = useCallback(async (id, data) => {
        return dispatch(updateRecording({
            id,
            data: {
                track_name: data.track_name,
                isrc: data.isrc,
                artist_name: data.artist_name
            }
        }));
    }, [dispatch]);

    const handleRightsSaveSuccess = useCallback(() => {
        // Handle successful rights save - maybe show a notification
    }, []);

    const handleRightsSaveError = useCallback((error) => {
        // Handle rights save error - maybe show an error notification
    }, []);

    const tabSchema = useMemo(() => [
        {
            label: 'Rights',
            icon: <Handshake size={23}/>,
            component: () => (
                <RightsForm mainRight={'Master'} workId={id}/>
                // <RightsFormContainer entityId={id} entityType="recording">
                //     <EnhancedRightsForm
                //         entityType="recording"
                //         onSaveSuccess={handleRightsSaveSuccess}
                //         onSaveError={handleRightsSaveError}
                //     />
                // </RightsFormContainer>
            ),
        },
        {
            label: 'Attachments',
            icon: <Paperclip size={23}/>,
            component: AttachmentsComponent,
        },
        {
            label: 'Notes',
            icon: <Notepad size={23}/>,
            component: NotesComponent,
        },
        {
            label: 'Metadata',
            icon: <Gear size={23}/>,
            component: () => <MetadataComponent item={recording}/>,
        },
    ], [id, handleRightsSaveSuccess, handleRightsSaveError]);

    return (
        <BFMTemplatePage
            component={
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((data) => updateData(id, data))}>
                        <GenericEntityView
                            schema={RECORDING_SCHEMA}
                            tabSchema={tabSchema}
                            entityName="Recording"
                            title="Recording"
                            fetchData={fetchData}
                            updateData={updateData}
                            loading={loading}
                            error={error}
                            entityData={recording}
                            control={methods.control}
                            id={id}
                        />
                    </form>
                </FormProvider>
            }
        />
    );
};

export default RecordingsView;