import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import EntityListView from '../../components/EntityListView';
import {fetchArtists, resetArtists} from "../../store/artistSlice";
import {fetchRecordings, resetRecordings} from "../../store/recordingSlice";

const ARTIST_CARD_SCHEMA = {
    header: [
        {key: 'track_name', variant: 'h6', color: 'text.primary', sx: {fontWeight: 'bold', mb: 1}},
    ],
    cardData: [
        {key: 'isrc', label: 'ISRC', variant: 'body2', color: 'text.secondary'},
    ],
    chip: {key: 'id', color: 'primary'},
};

const ARTIST_FILTER_CONFIG = [
    // {
    //     field: 'artistName',
    //     label: 'Artist Name',
    //     type: 'text'
    // }
];

const RecordingsList = ({writerId = null}) => {
    const dispatch = useDispatch();
    const {items, loading, error, pagination} = useSelector((state) => state.recordings);

    return (
        <EntityListView
            title="Recordings"
            items={items}
            loading={loading}
            error={error}
            pagination={pagination}
            createPath="/create?type=recording"
            filterConfig={ARTIST_FILTER_CONFIG}
            cardSchema={ARTIST_CARD_SCHEMA}
            navigateBasePath="/recordings"
            onFetch={(params) => dispatch(fetchRecordings(params))}
            onReset={() => dispatch(resetRecordings())}
            entityId={writerId}
            entityIdParam="writerId"
        />
    );
};

export default RecordingsList;