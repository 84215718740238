import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    MenuItem,
    IconButton,
    CircularProgress,
    Alert,
    Paper,
} from "@mui/material";
import { Plus, Trash, MagnifyingGlass as Save } from "@phosphor-icons/react";
import apiService from "../api/apiService";
import { getCurrentToken, initializeAuth } from "../services/authService"; // Import the auth functions
import { useDispatch } from "react-redux";

const ACCESS_LEVELS = [
    { value: "ADMIN", label: "Admin" },
    { value: "VIEWER", label: "Viewer" },
    { value: "EDITOR", label: "Editor" },
];

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [newUser, setNewUser] = useState({ email: "", access_level: "VIEWER" });
    const [isModified, setIsModified] = useState(false); // Tracks if there are unsaved changes

    const dispatch = useDispatch();

    // Fetch users on component mount
    const fetchUsers = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await apiService.get("/users");
            setUsers(data.users);
        } catch (err) {
            setError(err.message || "Error fetching users");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleAddUser = async () => {
        if (!newUser.email) {
            setError("Email is required to add a new user.");
            return;
        }

        setError(null);
        setLoading(true);

        try {
            await apiService.post("/users", newUser);
            setNewUser({ email: "", access_level: "VIEWER" });
            await fetchUsers();
        } catch (err) {
            setError(err.message || "Error adding user");
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateUserField = (index, field, value) => {
        const updatedUsers = [...users];
        updatedUsers[index][field] = value;
        setUsers(updatedUsers);
        setIsModified(true); // Mark changes as unsaved
    };

    const handleDeleteUser = async (email) => {
        if (!window.confirm("Are you sure you want to delete this user?")) return;

        setError(null);
        setLoading(true);

        try {
            await apiService.delete("/users", { data: { email } });
            await fetchUsers();
        } catch (err) {
            setError(err.message || "Error deleting user");
        } finally {
            setLoading(false);
        }
    };

    const refreshUserToken = async () => {
        try {
            const newToken = await getCurrentToken(); // Refresh token
            if (newToken) {
                await initializeAuth(dispatch); // Reinitialize authentication to update user state
            }
        } catch (error) {
            console.error("Error refreshing user token:", error);
        }
    };

    const saveChanges = async () => {
        setError(null);
        setLoading(true);

        try {
            await apiService.patch("/users", { users });
            setIsModified(false); // Reset modification state
            await refreshUserToken(); // Refresh token after saving changes
        } catch (err) {
            setError(err.message || "Error saving changes");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h4" gutterBottom>
                User Management
            </Typography>

            {loading && (
                <Box mb={3}>
                    <CircularProgress />
                </Box>
            )}

            {error && (
                <Box mb={3}>
                    <Alert severity="error">{error}</Alert>
                </Box>
            )}

            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Manage Existing Users
                </Typography>
                {users.map((user, index) => (
                    <Grid
                        key={user.uid || user.email}
                        container
                        spacing={2}
                        alignItems="center"
                        sx={{ mb: 2 }}
                    >
                        <Grid item xs={12} sm={5}>
                            <Typography>{user.email}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <TextField
                                fullWidth
                                select
                                label="Access Level"
                                variant="outlined"
                                value={user.access_level}
                                onChange={(e) =>
                                    handleUpdateUserField(index, "access_level", e.target.value)
                                }
                            >
                                {ACCESS_LEVELS.map((level) => (
                                    <MenuItem key={level.value} value={level.value}>
                                        {level.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteUser(user.email)}
                            >
                                <Trash />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
            </Paper>

            {isModified && (
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={saveChanges}
                        startIcon={<Save />}
                    >
                        Save Changes
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default UserManagement;