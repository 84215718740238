import React, {useMemo, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, FormProvider} from 'react-hook-form';

import GenericEntityView from '../../components/data/GenericEntityView';
import {BFMTemplatePage} from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";

import {Paperclip, Notepad, Gear, Users} from "@phosphor-icons/react";
import {fetchWriterById, updateWriter} from '../../store/writerSlice';
import WorksList from "../works/WorksList";
import WorksListContainer from "../works/WorksListContainer";

const WRITER_SCHEMA = [
    {dataKey: 'id', label: 'Writer ID', editable: false},
    {dataKey: 'name', label: 'Name', editable: true, titleKey: true},
    {dataKey: 'ipi', label: 'IPI', editable: true},
];

const WriterView = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {item: writer, loading, error} = useSelector((state) => state.writers);

    const fetchData = useCallback(
        async (id) => {
            return dispatch(fetchWriterById(id));
        },
        [dispatch]
    );

    const updateData = useCallback(
        async (id, data) => {
            const transformedData = {
                name: data.name,
                ipi: data.ipi,
            };
            return await dispatch(updateWriter({id, data: transformedData}));
        },
        [dispatch]
    );

    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id, fetchData]);

    const methods = useForm({
        defaultValues: {
            id: '',
            name: '',
            ipi: '',
            email: '',
            phone: '',
            status: '',
            created_at: '',
            updated_at: '',
            created_by: '',
            updated_by: '',
        },
    });

    const {handleSubmit, reset} = methods;

    useEffect(() => {
        if (writer) {
            reset({
                id: writer.id || '',
                name: writer.name || '',
                ipi: writer.ipi || '',
                email: writer.email || '',
                phone: writer.phone || '',
                status: writer.status || '',
                created_at: writer.created_at || '',
                updated_at: writer.updated_at || '',
                created_by: writer.created_by || '',
                updated_by: writer.updated_by || '',
            });
        }
    }, [writer, reset]);

    const tabSchema = useMemo(
        () => [
            {
                label: 'Works',
                icon: <Users size={23}/>,
                component: () => <WorksListContainer writerId={id}/>
            },
            {
                label: 'Attachments',
                icon: <Paperclip size={23}/>,
                component: AttachmentsComponent,
            },
            {
                label: 'Notes',
                icon: <Notepad size={23}/>,
                component: NotesComponent,
            },
            {
                label: 'Metadata',
                icon: <Gear size={23}/>,
                component: MetadataComponent,
            },
        ],
        []
    );

    return (
        <BFMTemplatePage
            component={
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit((data) => updateData(id, data))}>
                        <GenericEntityView
                            schema={WRITER_SCHEMA}
                            tabSchema={tabSchema}
                            entityName="Writer"
                            title="Writer"
                            fetchData={fetchData}
                            updateData={updateData}
                            loading={loading}
                            error={error}
                            entityData={writer}
                            control={methods.control}
                            id={id}
                        />
                    </form>
                </FormProvider>
            }
        />
    );
};

export default WriterView;