import React, {useMemo, useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, FormProvider} from 'react-hook-form';
import {
    MusicNoteSimple,
    Paperclip,
    Notepad,
    Gear
} from "@phosphor-icons/react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Typography,
    Chip,
    Link,
    CircularProgress
} from '@mui/material';

import GenericEntityView from '../../components/data/GenericEntityView';
import {BFMTemplatePage} from '../BFMTemplatePage';
import MetadataComponent from '../../components/data/MetadataComponent';
import NotesComponent from "../../components/data/NotesComponent";
import AttachmentsComponent from "../../components/attachments/AttachmentsComponent";
import {fetchTrackById, updateTrack} from "../../store/trackSlice";
import {fetchTrackRecordings, resetTrackRecordings} from "../../store/trackRecordingsSlice";

const TRACK_SCHEMA = [
    {dataKey: 'id', label: 'Track ID', editable: false},
    {dataKey: 'name', label: 'Track Name', editable: true, titleKey: true},
    {dataKey: 'song_code', label: 'Song Code', editable: true},
    {dataKey: 'genre', label: 'Genre', editable: true}
];

const RecordingsTable = ({trackId}) => {
    const dispatch = useDispatch();
    const {items: recordings, loading, error} = useSelector((state) => state.trackRecordings);

    useEffect(() => {
        if (trackId) {
            dispatch(fetchTrackRecordings({id: trackId}));
        }
        return () => {
            dispatch(resetTrackRecordings());
        };
    }, [trackId, dispatch]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p={3}>
                <CircularProgress/>
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={3}>
                <Typography color="error">Error loading recordings: {error}</Typography>
            </Box>
        );
    }
    console.log(recordings)

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader aria-label="recordings table">
                <TableHead>
                    <TableRow>

                        <TableCell>Recording Id</TableCell>
                        <TableCell>ISRC</TableCell>
                        <TableCell>Version (to be developed)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {recordings?.map((recording) => (
                        <TableRow key={recording.recording_id}>
                            <TableCell>
                                <Link href={`/recordings/${recording.recording_id}`} underline="hover">
                                    {recording.recording_id}
                                </Link>
                            </TableCell>

                            <TableCell>
                                {recording.isrc}
                            </TableCell>
                            <TableCell>Unavailable</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const TracksView = () => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {item: track, loading, error} = useSelector((state) => state.tracks);

    const methods = useForm({
        defaultValues: {
            id: '',
            name: '',
            song_code: '',
            genre: '',
            status: '',
            created_at: '',
            updated_at: '',
            created_by: '',
            updated_by: '',
        },
    });

    useEffect(() => {
        if (track) {
            methods.reset({
                id: track.id || '',
                name: track.name || '',
                song_code: track.song_code || '',
                genre: track.genre || '',
                status: track.status || '',
                created_at: track.created_at || '',
                updated_by: track.updated_by || '',
                updated_at: track.updated_at || '',
                created_by: track.created_by || '',
                is_deleted: track.is_deleted || false,
                create_method: track.create_method || '',
                version: track.version || null
            });
        }
    }, [track, methods.reset]);

    const fetchData = useCallback(async (id) => {
        return dispatch(fetchTrackById(id));
    }, [dispatch]);

    const updateData = useCallback(async (id, data) => {
        return dispatch(updateTrack({
            id,
            data: {
                name: data.name,
                song_code: data.song_code,
                genre: data.genre
            }
        }));
    }, [dispatch]);

    const tabSchema = useMemo(() => [
        {
            label: 'Recordings',
            icon: <MusicNoteSimple size={23}/>,
            component: () => <RecordingsTable trackId={id}/>,
        },
        {
            label: 'Attachments',
            icon: <Paperclip size={23}/>,
            component: AttachmentsComponent,
        },
        {
            label: 'Notes',
            icon: <Notepad size={23}/>,
            component: NotesComponent,
        },
        {
            label: 'Metadata',
            icon: <Gear size={23}/>,
            component: () => <MetadataComponent item={track}/>,
        },
    ], [id, track]);

    return (
        <BFMTemplatePage
            component={
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((data) => updateData(id, data))}>
                        <GenericEntityView
                            schema={TRACK_SCHEMA}
                            tabSchema={tabSchema}
                            entityName="Track"
                            title="Track"
                            fetchData={fetchData}
                            updateData={updateData}
                            loading={loading}
                            error={error}
                            entityData={track}
                            control={methods.control}
                            id={id}
                        />
                    </form>
                </FormProvider>
            }
        />
    );
};

export default TracksView;