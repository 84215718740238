import React from 'react';
import { Box, Tooltip } from '@mui/material';

const colors = {
    "Publisher Share": "rgb(247, 151, 103)", // Orange
    "Writer Share": "rgb(76, 142, 218)", // Blue
};

const RightsTypeIndicator = ({ publishers = [], writers = [] }) => {
    const rightsTypes = [];
    if (publishers.length > 0) rightsTypes.push("Publisher Share");
    if (writers.length > 0) rightsTypes.push("Writer Share");

    return (
        <Box sx={{ display: "flex", gap: 1 }}>
            {rightsTypes.map((type, index) => (
                <Tooltip key={index} title={type}>
                    <Box
                        sx={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            backgroundColor: colors[type] || "gray",
                        }}
                    />
                </Tooltip>
            ))}
        </Box>
    );
};

export default RightsTypeIndicator;