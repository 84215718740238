import createEntitySlice from './base/baseEntitySlice';

const {reducer, actions} = createEntitySlice({
    name: 'rights',
    endpoint: '/rights',
});

export const {
    fetchEntityById: fetchRightsById,
    updateEntity: updateRights,
    resetState: resetPublishers,
} = actions;

export default reducer;