import {configureStore} from '@reduxjs/toolkit';
import tabsReducer from './tabSlice'
import userReducer from './userSlice.js'
import autocompleteReducer from './autocompleteSlice';
import formReducer from './formSlice'
import dealsReducer from './dealSlice'
import noteReducer from "./noteSlice";
import writersReducer from './writerSlice'
import businessEntitiesReducer from "./businessEntitiesSlice";
import worksReducer from './workSlice'
import publisherReducer from './publisherSlice'
import sessionReducer from './sessionSlice';
import rightReducer from "./rightSlice";
import filterReducer from './filterSlice'
import artistReducer from './artistSlice'
import recordingReducer from './recordingSlice'
import trackReducer from './trackSlice'
import dealAssetReducer from "./dealAssetSlice";
import trackRecordingsReducer from "./trackRecordingsSlice";

const store = configureStore({
    reducer: {
        tabs: tabsReducer,
        user: userReducer,
        autocomplete: autocompleteReducer,
        form: formReducer,
        deals: dealsReducer,
        notes: noteReducer,
        businessEntities: businessEntitiesReducer,
        writers: writersReducer,
        works: worksReducer,
        publishers: publisherReducer,
        session: sessionReducer,
        rights: rightReducer,
        filters: filterReducer,
        artists: artistReducer,
        recordings: recordingReducer,
        tracks: trackReducer,
        dealAssets: dealAssetReducer,
        trackRecordings: trackRecordingsReducer,


    },
});

export default store;