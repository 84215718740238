import React from 'react';
import {Box, Typography, Divider, Paper, List, ListItem, ListItemText, Alert} from '@mui/material';
import {Info} from '@phosphor-icons/react';

const HelpPage = () => {
    return (
        <Box p={4} maxWidth={1200} margin="auto">
            <Typography variant="h4" gutterBottom>
                Help & Documentation
            </Typography>

            <Paper elevation={3} sx={{p: 4, mb: 4}}>
                <Typography variant="h5" gutterBottom>
                    Getting Started
                </Typography>
                <Typography paragraph>
                    Welcome to the Repertoire Management System. This application helps you manage your musical assets,
                    including works, recordings, and associated rights. You'll need to sign in with your Google account
                    to access the system.
                </Typography>

                <Divider sx={{my: 3}}/>

                <Typography variant="h6" gutterBottom>
                    Core Components
                </Typography>
                <Typography paragraph>
                    The system is organized into logical groupings to help manage your catalogue:
                </Typography>
                <List>
                    {/* Business and Commercial Section */}
                    <ListItem>
                        <ListItemText
                            primary="Business Entities"
                            secondary="Companies and organisations managing rights and catalogues"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Deals"
                            secondary="Agreements and contracts governing rights management"
                        />
                    </ListItem>

                    {/* Recording Section */}
                    <ListItem>
                        <ListItemText
                            primary="Artists"
                            secondary="Manage recording artists and their catalogues"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Tracks"
                            secondary="Individual recordings linked to works and artists"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Recordings"
                            secondary="Master recordings identified by ISRC codes"
                        />
                    </ListItem>

                    {/* Publishing Section */}
                    <ListItem>
                        <ListItemText
                            primary="Writers"
                            secondary="Track your writers using their IPI numbers"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Works"
                            secondary="Musical compositions with their ISWC codes and writer shares"
                        />
                    </ListItem>
                </List>

                <Divider sx={{my: 3}}/>
                <Typography variant="h6" gutterBottom>
                    Uploading a Schedule of Work
                </Typography>
                <Typography paragraph>
                    To upload a new schedule of work:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="1. Navigate to the Utilities section"
                            secondary="You'll find this in the main navigation menu"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="2. Select 'File Uploader'"
                            secondary="Templates for both Recording and Publishing schedules can be downloaded directly from the file uploader page"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="3. Choose your schedule type"
                            secondary="Select either 'Publishing' or 'Recording' schedule and download the corresponding template"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="4. Fill in the required fields"
                            secondary="Including right type, deal, territories, and control status"
                        />
                    </ListItem>
                </List>

                <Alert severity="info" sx={{mt: 2}}>
                    For publishing schedules, you can choose whether to auto-calculate mechanical shares (x2 of the
                    ownership percentage in the schedule).
                </Alert>

                <Divider sx={{my: 3}}/>

                <Typography variant="h6" gutterBottom>
                    Managing Your Catalogue
                </Typography>
                <Typography paragraph>
                    For any entity (Business Entities, Deals, Artists, Works, etc.):
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Creating New Entries"
                            secondary="Click the 'Create [Entity]' button at the top of any list view and fill in the required details"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Updating Existing Entries"
                            secondary="Open the entity and click the edit icon next to any editable field. Changes are saved automatically."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Deleting Entries"
                            secondary="Open the entity and click the delete button in the top-right corner. This action cannot be undone."
                        />




                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Adding Notes & Attachments"
                            secondary="Each entity supports notes and file attachments for additional documentation"
                        />
                    </ListItem>
                </List>

                <Divider sx={{my: 3}}/>

                <Typography variant="h6" gutterBottom>
                    Searching and Filtering
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="Quick Search"
                            secondary="Use the search chip to quickly find items by name"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Advanced Filters"
                            secondary="Click any filter chip to set specific criteria (e.g., ISWC, IPI, status)"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="Clearing Filters"
                            secondary="Use the 'Clear All' chip to reset your search"
                        />
                    </ListItem>
                </List>

                <Divider sx={{my: 3}}/>

                <Typography variant="h6" gutterBottom>
                    Access Levels
                </Typography>
                <Typography paragraph>
                    The system has three permission levels:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemText
                            primary="VIEWER"
                            secondary="Can view catalogue entries and download reports"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="EDITOR"
                            secondary="Can create and edit catalogue entries, upload schedules, and manage rights"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary="ADMIN"
                            secondary="Full system access including user management and system configuration"
                        />
                    </ListItem>
                </List>

                <Alert severity="info" sx={{mt: 2}}>
                    For access level changes or technical support, please contact your system administrator.
                </Alert>
            </Paper>
        </Box>
    );
};

export default HelpPage;