// src/schemas/scheduleOfWorkSchema.js
export const publishingScheduleSchema = [
    {
        step: 0,
        fields: [
            {
                name: 'deal_id',
                type: 'autocomplete',
                label: 'Deal Name',
                placeholder: 'Search or create new Deal',
                endpoint: '/deals',
                dataKey: 'deals',
                createNewUrl: '/deals/create',
                required: true,
                tooltip: 'Select the Deal that these assets are from.',
            },
        ],
    },
    {
        step: 1,
        fields: [
            {
                name: 'territories',
                type: 'countrySelector',
                label: 'Territories',
                required: true,
                tooltip:
                    'Select the relevant territories for this right type. For complex rights types, either upload a new schedule for each variation OR manage the works directly once they are loaded.',
            },
            {
                name: 'right_type',
                type: 'select',
                label: 'Right Type',
                options: [
                    {value: 'writer_share', label: 'Writer Share'},
                    {value: 'publisher_share', label: 'Publisher Share'},
                    {value: 'administration', label: 'Administration'},
                ],
                required: true,
                tooltip:
                    'Select the type of right for this schedule of work. If a deal has multiple types of right, upload a schedule for each.',
            },
            {
                name: 'is_controlled',
                type: 'switch',
                label: 'Is Controlled (Exclusive/Active)',
                defaultValue: true,
                tooltip:
                    'Determines active/passive rights. If passive, please add reversion date and Third Party Admin.',
            },
            {
                name: 'file',
                type: 'fileDropzone',
                label: 'Upload Schedule',
                accept: 'text/csv',
                required: true,
                tooltip: 'Upload a CSV file with schedule data.',
            },
        ],
    },
];

export const recordingsScheduleSchema = [
    {
        step: 0,
        fields: [
            {
                name: 'deal_id',
                type: 'autocomplete',
                label: 'Deal Name',
                placeholder: 'Search or create new Deal',
                endpoint: '/deals',
                dataKey: 'deals',
                createNewUrl: '/deals/create',
                required: true,
                tooltip: 'Select the Deal that these assets are from.',
            },
        ],
    },
    {
        step: 1,
        fields: [
            {
                name: 'territories',
                type: 'countrySelector',
                label: 'Territories',
                required: true,
                tooltip:
                    'Select the relevant territories for this right type. For complex rights types, either upload a new schedule for each variation OR manage the works directly once they are loaded.',
            },
            {
                name: 'right_type',
                type: 'select',
                label: 'Right Type',
                options: [
                    {value: 'recording_ownership', label: 'Recording Ownership'},
                    {value: 'distribution', label: 'Distribution'},
                    {value: 'neighboring_rights', label: 'Neighboring Rights'},
                    {value: 'administration', label: 'Administration'},
                ],
                required: true,
                tooltip:
                    'Select the type of right for this schedule of work. If a deal has multiple types of right, upload a schedule for each.',
            },
            {
                name: 'is_controlled',
                type: 'switch',
                label: 'Is Controlled (Exclusive/Active)',
                defaultValue: true,
                tooltip:
                    'Determines active/passive rights. If passive, please add reversion date and Third Party Admin.',
            },
            {
                name: 'file',
                type: 'fileDropzone',
                label: 'Upload Schedule',
                accept: 'text/csv',
                required: true,
                tooltip: 'Upload a CSV file with schedule data.',
            },
        ],
    },
];