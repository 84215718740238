import React, { useState } from 'react';
import { Box, Button, Snackbar, CircularProgress } from '@mui/material';
import { ArrowsClockwise } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import apiService from "../../api/apiService";
const RefreshWeeklyReport = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const handleRefresh = async () => {
        const endpoint = '/utils/run-stream-report';
        console.log('Attempting to call endpoint using apiService:', endpoint);

        setIsLoading(true);
        try {
            const response = await apiService.post(endpoint);
            console.log('Response from API:', response);

            setSnackbar({
                open: true,
                message: 'Weekly stream report refreshed successfully',
                severity: 'success'
            });

            setTimeout(() => {
                navigate(-1);
            }, 1500);

        } catch (error) {
            console.error('Error refreshing report:', error);
            console.log('Error details:', {
                name: error.name,
                message: error.message,
                response: error.response
            });

            setSnackbar({
                open: true,
                message: error.response?.data?.detail || 'Failed to refresh report. Please try again.',
                severity: 'error'
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    return (
        <Box p={2} display="flex" flexDirection="column" alignItems="center">
            <Button
                variant="contained"
                color="primary"
                onClick={handleRefresh}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <ArrowsClockwise size={20} />}
                sx={{ minWidth: 200 }}
            >
                {isLoading ? 'Refreshing...' : 'Refresh Weekly Report'}
            </Button>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Box>
    );
};

export default RefreshWeeklyReport;