import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
    Box,
    Typography,
    Chip,
    CircularProgress
} from '@mui/material';
import { fetchDealAssets, resetDealAssets } from '../../store/dealAssetSlice';
import EntityFilters from "../../components/EntityFilters";

const getAssetRoute = (assetType, assetId) => {
    const routes = {
        'Work': `/works/${assetId}`,
        'Recording': `/recordings/${assetId}`
    };
    return routes[assetType] || '#';
};

const getParticipantRoute = (participantType, participantId) => {
    const routes = {
        'Work': `/writers/${participantId}`,
        'Recording': `/artists/${participantId}`
    };
    return routes[participantType] || '#';
};

const ASSET_FILTER_CONFIG = [
    { field: 'songCode', label: 'Song Code', type: 'text' },
    { field: 'participantName', label: 'Participant Name', type: 'text' }
];

export const DealAssets = ({ dealId }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { items, loading, error, pagination } = useSelector((state) => state.dealAssets);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [filters, setFilters] = useState({});

    // Reset pagination when dealId changes
    useEffect(() => {
        setPage(0);
        setFilters({});
    }, [dealId]);

    const fetchData = async (params) => {
        setIsFilterLoading(true);
        try {
            await dispatch(fetchDealAssets({
                id: dealId,
                params: {
                    ...params,
                    offset: page * rowsPerPage,
                    limit: rowsPerPage
                }
            }));
        } finally {
            setIsFilterLoading(false);
        }
    };

    // Effect to handle initial load and parameter changes
    useEffect(() => {
        let mounted = true;

        const params = {
            offset: page * rowsPerPage,
            limit: rowsPerPage,
            ...filters
        };

        if (dealId && mounted) {
            fetchData(params);
        }

        return () => {
            mounted = false;
            dispatch(resetDealAssets());
        };
    }, [dealId, page, rowsPerPage, dispatch, location.pathname, filters]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        setRowsPerPage(newRowsPerPage);
        setPage(0);
    };

    const handleFilter = async (newFilters) => {
        setPage(0);
        setFilters(newFilters);
    };

    if (loading || isFilterLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" p={3}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box p={3}>
                <Typography color="error">Error loading assets: {error}</Typography>
            </Box>
        );
    }

    return (
        <Box>
            <Box mb={3}>
                <EntityFilters
                    onFilter={handleFilter}
                    filterConfig={ASSET_FILTER_CONFIG}
                    entityType="assets"
                    searchParamName="assetName"
                    isLoading={isFilterLoading}
                    initialFilters={filters}
                />
            </Box>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="deal assets table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Asset Type</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Code</TableCell>
                                <TableCell>Writers/Artists</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((asset) => (
                                <TableRow key={asset.asset_id}>
                                    <TableCell>
                                        <Chip
                                            label={asset.asset_type}
                                            color={asset.asset_type === 'Work' ? 'primary' : 'secondary'}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            to={getAssetRoute(asset.asset_type, asset.asset_id)}
                                            style={{
                                                textDecoration: 'none',
                                                color: '#1976d2',
                                                '&:hover': {
                                                    textDecoration: 'underline'
                                                }
                                            }}
                                        >
                                            {asset.asset_name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{asset.song_code}</TableCell>
                                    <TableCell>
                                        {asset.writer_artist_list?.map((participant, index) => (
                                            <Link
                                                key={`${participant.participant_id}-${index}`}
                                                to={getParticipantRoute(asset.asset_type, participant.participant_id)}
                                                style={{ textDecoration: 'none' }}
                                            >
                                                <Chip
                                                    label={participant.participant}
                                                    size="small"
                                                    sx={{
                                                        mr: 0.5,
                                                        mb: 0.5,
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            backgroundColor: 'action.hover'
                                                        }
                                                    }}
                                                />
                                            </Link>
                                        ))}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={pagination?.rowCount || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default DealAssets;