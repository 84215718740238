import createEntitySlice from "./base/baseEntitySlice";


const {reducer, actions} = createEntitySlice({
    name: 'artists',
    endpoint: '/artists',
});

export const {
    fetchEntities: fetchArtists,
    fetchEntityById: fetchArtistById,
    createEntity: createArtist,
    updateEntity: updateArtist,
    deleteEntity: deleteArtist,
    resetState: resetArtists,
} = actions;

export default reducer;