import {Container} from "@mui/material";

export function BFMTemplatePage({component}) {


    return (
        <>
            <Container className = 'asd' sx={{paddingLeft: '10vw', paddingRight: '10vw'}}>
                {component}
            </Container>
        </>
    )
}