// src/components/EntityFilters.js
import React, {useState, useRef, useCallback} from 'react';
import {
    Box,
    TextField,
    Chip,
    Popover,
    Paper,
    InputAdornment,
    IconButton,
    Stack,
    MenuItem, Typography, Grid
} from '@mui/material';
import {ArrowDown, MagnifyingGlass, MagnifyingGlass as Search, X} from "@phosphor-icons/react";
import debounce from 'lodash/debounce';

const FilterPopover = ({
                           open,
                           anchorEl,
                           onClose,
                           value,
                           onChange,
                           config,
                           onClear
                       }) => {
    const inputRef = useRef(null);

    React.useEffect(() => {
        if (open && inputRef.current) {
            setTimeout(() => inputRef.current.focus(), 100);
        }
    }, [open]);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            PaperProps={{
                sx: {mt: 1, width: 300}
            }}
        >
            <Box p={2}>
                <TextField
                    inputRef={inputRef}
                    fullWidth
                    size="small"
                    placeholder={`Filter by ${config.label}...`}
                    value={value || ''}
                    onChange={onChange}
                    select={config.type === 'select'}
                    InputProps={{
                        startAdornment: config.type !== 'select' && (
                            <InputAdornment position="start">
                                <Search size={20}/>
                            </InputAdornment>
                        ),
                        endAdornment: value && (
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    size="small"
                                    onClick={onClear}
                                >
                                    <X size={16}/>
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                >
                    {config.type === 'select' && config.options?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
        </Popover>
    );
};

const EntityFilters = ({
                           onFilter,
                           filterConfig,
                           entityType,
                           searchParamName = 'name',
                           isLoading = false,
                           initialFilters = {}
                       }) => {
    const [filters, setFilters] = useState(initialFilters);
    const [activeFilter, setActiveFilter] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const debouncedFilter = useCallback(
        debounce((newFilters) => {
            onFilter(newFilters);
        }, 300),
        [onFilter]
    );

    const handleChipClick = (event, filterKey) => {
        setActiveFilter(filterKey);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setActiveFilter(null);
        setAnchorEl(null);
    };

    const handleFilterChange = (field) => (event) => {
        const value = event.target.value;
        console.log('Filter changing:', field, value);
        const newFilters = {
            ...filters,
            [field]: value
        };

        // Clean empty values
        Object.keys(newFilters).forEach(key => {
            if (!newFilters[key] || newFilters[key].trim() === '') {
                delete newFilters[key];
            }
        });

        console.log('New filters after change:', newFilters);
        setFilters(newFilters);
        debouncedFilter(newFilters);
    };


    const handleClearFilter = (field) => {
        const newFilters = {...filters};
        delete newFilters[field];
        setFilters(newFilters);
        debouncedFilter(newFilters);
        handlePopoverClose();
    };

    const handleReset = () => {
        setFilters({});
        debouncedFilter({});
        handlePopoverClose();
    };

    const allConfigs = [
        {field: searchParamName, label: 'Search Name', type: 'text'},
        ...filterConfig
    ];

    return (
        <Paper elevation={1} sx={{mb: 1.5}} variant='outlined'>
            <Box pt={1} display="flex" alignItems="center" gap={1} pb={1} pl={2 } pr={2}>
                <Stack direction="row" spacing={1} flexWrap="wrap" gap={1}>
                    <Stack direction={'row'} alignItems={'center'} gap={1}>
                        <Typography>Filters</Typography>
                        <MagnifyingGlass/>
                    </Stack>
                    {allConfigs.map((config) => {
                        const hasValue = filters[config.field];

                        return (
                            <Chip
                                key={config.field}
                                label={hasValue ? `${config.label}: ${filters[config.field]}` : config.label}
                                onClick={(e) => handleChipClick(e, config.field)}
                                onDelete={hasValue ? () => handleClearFilter(config.field) : undefined}
                                color={hasValue ? "primary" : "default"}
                                variant={hasValue ? "filled" : "outlined"}
                                disabled={isLoading}
                                icon={<ArrowDown/>}
                            />
                        );
                    })}

                    {Object.keys(filters).length > 0 && (
                        <Chip
                            label="Clear All"
                            onClick={handleReset}
                            disabled={isLoading}
                            color="error"
                            variant="outlined"
                        />
                    )}
                </Stack>

                {activeFilter && (
                    <FilterPopover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        value={filters[activeFilter]}
                        onChange={handleFilterChange(activeFilter)}
                        config={allConfigs.find(c => c.field === activeFilter)}
                        onClear={() => handleClearFilter(activeFilter)}
                    />
                )}
            </Box>
        </Paper>
    );
};

export default EntityFilters;