import React, {useState, useEffect, useCallback} from 'react';
import {
    Box,
    Button,
    IconButton,
    Typography,
    TextField,
    Switch,
    FormControlLabel,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Alert,
    CircularProgress,
} from '@mui/material';
import {Plus, Trash, CaretDown, LockSimple, LockOpen} from "@phosphor-icons/react";
import {useDispatch, useSelector} from 'react-redux';
import {useFormContext, useFieldArray, Controller} from 'react-hook-form';
import CountrySelector from "./CountrySelector";
import GenericAutocomplete from "../lookup/GenericAutocomplete";
import RightsTypeIndicator from "../RightsTypeIndicator";
import {fetchRightsById, updateRights} from "../../store/rightSlice";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const FORM_FIELD_HEIGHT = '48px';

const RIGHT_TYPES = [
    {value: 'writer_share', label: 'Writer Share'},
    {value: 'publisher_share', label: 'Publisher Share'},
    {value: 'recording_ownership', label: 'Recording Ownership'},
    {value: 'distribution', label: 'Distribution'},
    {value: 'neighboring_rights', label: 'Neighboring Rights'},
    {value: 'administration', label: 'Administration'},
];

const NON_PERCENTAGE_RIGHTS = ['recording_ownership', 'distribution', 'neighboring_rights', 'administration'];

const ShareValidation = {
    max: {value: 100, message: 'Share cannot exceed 100%'},
    min: {value: 0, message: 'Share cannot be negative'},
    validate: {
        isNumber: value => !isNaN(value) || 'Must be a valid number',
    },
};
const RightsForm = ({workId, mainRight}) => {
    const dispatch = useDispatch();
    const {item: rightsData, loading} = useSelector(state => state.rights);
    const {control, register, watch, handleSubmit, reset} = useFormContext();
    const {fields, append, remove} = useFieldArray({
        control,
        name: "rights",
        keyName: "_id",
    });

    const [expandedPanel, setExpandedPanel] = useState(null);
    const [lockedPanels, setLockedPanels] = useState({});
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [formKey, setFormKey] = useState(0);

    useEffect(() => {
        if (workId) {
            dispatch(fetchRightsById(workId));
        }
    }, [workId, dispatch]);

    console.log(rightsData)
    // console.log(rightsData?.rights[0].is_controlled, 'I AM THE IS CONTROLLED THING ')
    useEffect(() => {
        if (rightsData) {
            const formattedRights = rightsData.rights?.map(right => ({
                ...right,
                perf_share: right.perf_share !== null ? right.perf_share * 100 : null,
                mechanical_share: right.mechanical_share!== null ? right.mechanical_share* 100 : null,
                is_controlled: right.is_controlled,
                third_party_admin: right.third_party_admin || '',
                reversion_date: right.reversion_date || null,
            })) || [];

            reset({id: workId, rights: formattedRights});

            const newLockedPanels = {};
            formattedRights.forEach((_, index) => {
                newLockedPanels[index] = true;
            });
            setLockedPanels(newLockedPanels);
            setFormKey(prev => prev + 1);
        }
    }, [rightsData, reset, workId]);

    const handleAccordionChange = useCallback((panel) => (event, isExpanded) => {
        if (!event.target.closest('.MuiIconButton-root')) {
            setExpandedPanel(isExpanded ? panel : null);
        }
    }, []);

    const toggleLock = useCallback((index, event) => {
        event.stopPropagation();
        setLockedPanels(prev => ({
            ...prev,
            [index]: !prev[index],
        }));
    }, []);

    const handleRemoveRight = useCallback((index, event) => {
        event.stopPropagation();
        remove(index);
        setLockedPanels(prev => {
            const newLockedPanels = {...prev};
            for (let i = index; i < Object.keys(newLockedPanels).length - 1; i++) {
                newLockedPanels[i] = newLockedPanels[i + 1];
            }
            delete newLockedPanels[Object.keys(newLockedPanels).length - 1];
            return newLockedPanels;
        });
    }, [remove]);

    const addRight = useCallback(() => {
        const newIndex = fields.length;
        append({
            right_id: null,
            deal_id: '',
            perf_share: 0,
            right_type: 'writer_share',
            is_controlled: true,
            territories: [],
            mechanical_share: null,
            third_party_admin: '',
            reversion_date: null,
        });
        setExpandedPanel(`panel${newIndex}`);
        setLockedPanels(prev => ({
            ...prev,
            [newIndex]: false,
        }));
    }, [fields.length, append]);

    const onSubmit = async (data) => {
        setIsSaving(true);
        setSaveError(null);

        try {
            await dispatch(updateRights({
                id: workId,
                data: {
                    rights: data.rights.map(right => ({
                        ...right,
                        perf_share: right.perf_share !== null ? right.perf_share / 100 : null,
                        mechanical_share: right.right_type === 'publisher_share'
                            ? (right.mechanical_share !== null ? right.mechanical_share/ 100 : null)
                            : null,
                        is_controlled: right.is_controlled,
                        third_party_admin: right.third_party_admin || null,
                        reversion_date: right.reversion_date || null,
                    })),
                },
            })).unwrap();
            dispatch(fetchRightsById(workId));
        } catch (error) {
            setSaveError(error.message || 'Failed to save changes');
        } finally {
            setIsSaving(false);
        }
    };

    const getRightTypeLabel = (type) => {
        const right = RIGHT_TYPES.find(r => r.value === type);
        return right ? right.label : type;
    };

    const commonFieldStyles = {
        '& .MuiInputBase-root': {
            height: FORM_FIELD_HEIGHT,
        },
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress/>
            </Box>
        );
    }
    return (
        <Box sx={{p: 2}} key={formKey}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                <Typography variant="h6">{mainRight} Rights</Typography>
                <Box>
                    <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        color="primary"
                        sx={{mr: 2}}
                        disabled={isSaving}
                    >
                        {isSaving ? 'Saving...' : 'Save Changes'}
                    </Button>
                    <Button
                        startIcon={<Plus size={20}/>}
                        onClick={addRight}
                        variant="outlined"
                        color="primary"
                    >
                        Add Right
                    </Button>
                </Box>
            </Box>

            {saveError && (
                <Alert severity="error" sx={{mb: 2}}>
                    {saveError}
                </Alert>
            )}

            {fields.map((field, index) => {
                const rightType = watch(`rights.${index}.right_type`);
                const showPercentage = !NON_PERCENTAGE_RIGHTS.includes(rightType);

                return (
                    <Accordion
                        key={`${field._id}-${formKey}`}
                        expanded={expandedPanel === `panel${index}`}
                        onChange={handleAccordionChange(`panel${index}`)}
                        sx={{mb: 2}}
                    >
                        <AccordionSummary
                            expandIcon={<CaretDown/>}
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                },
                            }}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                <RightsTypeIndicator
                                    publishers={rightType === 'publisher_share' ? [{}] : []}
                                    writers={rightType === 'writer_share' ? [{}] : []}
                                />
                                <Typography>
                                    {getRightTypeLabel(rightType)}
                                    {showPercentage && ` - ${watch(`rights.${index}.perf_share`)}%`}
                                </Typography>
                            </Box>
                            <IconButton
                                size="small"
                                onClick={(e) => toggleLock(index, e)}
                                sx={{ml: 2}}
                            >
                                {lockedPanels[index] ? <LockSimple size={20}/> : <LockOpen size={20}/>}
                            </IconButton>
                        </AccordionSummary>


                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name={`rights.${index}.deal_id`}
                                        control={control}
                                        render={({field}) => (
                                            <GenericAutocomplete
                                                endpoint="/deals"
                                                dataKey="deals"
                                                label="Deal Name"
                                                value={field.value}
                                                onChange={(value) => field.onChange(value)}
                                                disabled={lockedPanels[index]}
                                                sx={commonFieldStyles}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Controller
                                        name={`rights.${index}.right_type`}
                                        control={control}
                                        render={({field: rightTypeField}) => (
                                            <TextField
                                                {...rightTypeField}
                                                select
                                                fullWidth
                                                label="Right Type"
                                                size="small"
                                                disabled={lockedPanels[index]}
                                                value={rightTypeField.value || 'writer_share'}
                                                sx={commonFieldStyles}
                                            >
                                                {RIGHT_TYPES.map(type => (
                                                    <MenuItem key={type.value} value={type.value}>
                                                        {type.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                                {showPercentage && (
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name={`rights.${index}.perf_share`}
                                            control={control}
                                            rules={ShareValidation}
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    label="Performance Share %"
                                                    type="number"
                                                    fullWidth
                                                    size="small"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    disabled={lockedPanels[index]}
                                                    inputProps={{step: "1", min: "0", max: "100"}}
                                                    sx={commonFieldStyles}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                {rightType === 'publisher_share' && (
                                    <Grid item xs={12} sm={6}>
                                        <Controller
                                            name={`rights.${index}.mechanical_share`}
                                            control={control}
                                            rules={ShareValidation}
                                            render={({field, fieldState: {error}}) => (
                                                <TextField
                                                    {...field}
                                                    label="Mechanical Share %"
                                                    type="number"
                                                    fullWidth
                                                    size="small"
                                                    error={!!error}
                                                    helperText={error?.message}
                                                    disabled={lockedPanels[index]}
                                                    inputProps={{step: "1", min: "0", max: "100"}}
                                                    sx={commonFieldStyles}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                                <Grid container item xs={9} spacing={2} alignItems="center">
                                    <Grid item xs={8} sm={8}>
                                            <CountrySelector
                                                name={`rights.${index}.territories`}
                                                control={control}
                                                multiple
                                                disabled={lockedPanels[index]}
                                                sx={{
                                                    ...commonFieldStyles,
                                                    pointerEvents: lockedPanels[index] ? 'none' : 'auto',
                                                    width: '100%',
                                                }}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name={`rights.${index}.is_controlled`}
                                                    control={control}
                                                    render={({field: {value, onChange, ...field}}) => (
                                                        <Switch
                                                            {...field}
                                                            checked={value}
                                                            onChange={onChange}
                                                            disabled={lockedPanels[index]}
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Is Controlled"
                                        />
                                    </Grid>
                                </Grid>
                                {!watch(`rights.${index}.is_controlled`) && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                {...register(`rights.${index}.third_party_admin`)}
                                                label="Third Party Admin"
                                                size="small"
                                                fullWidth
                                                disabled={lockedPanels[index]}
                                                sx={commonFieldStyles}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Controller
                                                name={`rights.${index}.reversion_date`}
                                                control={control}
                                                render={({field}) => (
                                                    <DatePicker
                                                        label="Reversion Date"
                                                        value={field.value ? new Date(field.value) : null}
                                                        onChange={(date) => field.onChange(date)}
                                                        disabled={lockedPanels[index]}
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                size: "small",
                                                                sx: commonFieldStyles
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <IconButton
                                        onClick={(e) => handleRemoveRight(index, e)}
                                        color="error"
                                        disabled={lockedPanels[index]}
                                    >
                                        <Trash size={20}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            })}

            {fields.length === 0 && (
                <Typography color="text.secondary" sx={{textAlign: 'center', py: 4}}>
                    No rights added yet. Click "Add Right" to begin.
                </Typography>
            )}
        </Box>
    );
};

export default RightsForm;