import createEntitySlice from "./base/baseEntitySlice";


const {reducer, actions} = createEntitySlice({
    name: 'recordings',
    endpoint: '/recordings',
});

export const {
    fetchEntities: fetchRecordings,
    fetchEntityById: fetchRecordingById,
    createEntity: createRecording,
    updateEntity: updateRecording,
    deleteEntity: deleteRecording,
    resetState: resetRecordings,
} = actions;

export default reducer;